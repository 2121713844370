.borderGap {
  border-bottom: 1px dashed #e5eaee;
  margin-bottom: 20px;
  width: 100%;
}

.site-name {
  color: #fff;
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
}

@media (min-width: 992px) {
  .header-fixed.subheader-fixed.subheader-enabled .wrapper {
    padding-top: 70px;
  }
}

@media (max-width: 991.98px) {
  .subheader-enabled .content {
    /* padding-top: 15px; */
  }
}

.aside-minimize .brand .brand-logo span {
  display: none;
}

.site_name_login {
  text-transform: uppercase;
  font-weight: 800;
  color: #2f3044;
  margin-top: -5px !important;
}

.login-label {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #000000 !important;
}

/* Design Dash Board */

.shipping-card {
  background-color: #4a7dff;
  padding-top: 30px;
  padding-bottom: 5px !important;
}

.shipping-card h2 {
  color: white;
  font-weight: 800;
  padding-top: 15px;
}

.second-color {
  background-color: #3699ff;
}

.shipping-card p {
  color: white;
  font-size: 12px;
}

.spacer {
  padding-left: 30px;
}

/* Design Dash Board */

/* custom form design */

.formHeight {
  height: 30px !important;
  background: #fff;
  font-size: 10px !important;
  font-weight: 400;
}

.css-1fhf3k1-control {
  height: 30px !important;
  min-height: 30px !important;
}

.form-input-white {
  background: #fff;
}

.formFont {
  font-size: 10px !important;
  margin-bottom: 0px !important;
}

button.saveButton {
  height: 29px;
  line-height: 10px;
  background-color: #789dfe !important;
  font-weight: 400;
  border-radius: 3px;
}

.cancelButton {
  background-color: #e5eaee !important;
  /* height: 29px; */
  line-height: 12px !important;
  color: #464e5f !important;
  font-weight: 400;
  border-radius: 3px;
}

.formSelect {
  height: 30px;
  font-size: 10px !important;
  background: #f3f6f9;
  font-weight: 500;
  padding-top: 5px;
}

.chooseFileText {
  color: #b5b5c3;
}

.fileInput {
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

input.fileInput,
span#inputGroupPrepend {
  height: 30px;
}

/* DashBoard Main Page Design Starts */

/* DashBoard Main Page top card by efaj starts */

.top_card {
  margin-top: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  padding: 10px;
  border-radius: 13px;
  overflow: hidden;
  color: black;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.top_card_so {
  margin-top: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: linear-gradient(to bottom right, #fae9e9, #ffffff);
  padding: 10px;
  border-radius: 13px;
  overflow: hidden;
  color: black;
  position: relative;
  text-align: center;

}

.top_card_dashboardChart {
  margin-top: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: rgb(225, 249, 243);
  padding: 10px;
  /* border-radius: 13px; */
  overflow: hidden;
  color: black;
  position: relative;
  text-align: center;
}

.top_card_dashboardChart_cfo {
  margin-top: 3px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  padding: 10px;
  /* border-radius: 13px; */
  overflow: hidden;
  color: black;
  /* position: relative; */
  text-align: center;
}

.sub_top_card_dashboardChart {
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  padding: 5px;
  overflow: hidden;
  color: black;
  position: absolute;
  text-align: center;
  border: 1px solid #02a423;
  top: 200px;
  left: 390px;
  font-size: 11px;
}

.sub2_top_card_dashboardChart {
  margin-top: 43px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  padding: 5px;
  overflow: hidden;
  color: black;
  position: absolute;
  text-align: center;
  border: 1px solid #543f05;
  top: 188px;
  left: 390px;
  font-size: 11px;
}

.top_card2 {

  box-shadow: 0px 3px 6px #2c28281c;
  background: rgb(6, 11, 103);
  padding: 15px;
}

.top_card3 {
  padding: 10px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #0b8794;
  /* font-family: fantasy;  */

}

.top_card4 {
  padding: 2px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #0b8794;
  /* font-family: fantasy;  */

}

.topCard_img img {
  width: 45px;

}

.salesDashboardtext {
  color: #c1c9d6;
}

.dashboardSalesCardDataColor {
  font-size: 20px;
  color: #000000;
  font-weight: 500;
}

.sales_card {
  margin-top: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  overflow: hidden;
  color: black;
  padding: 17px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.pie_chart_card {
  margin-top: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  overflow: hidden;
  color: black;
  padding: 08px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.acheivement_chart_card {
  margin-top: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  overflow: hidden;
  color: black;
  padding: 27px;
  overflow: hidden;
  position: relative;
  text-align: center;

}

.circ {
  height: 10px !important;
}

/* DashBoard Main Page top card by efaj ends */



.dashboard__pb {
  padding-bottom: 30px;
}

.paid__widget_New {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px;
  overflow: hidden;
  background: #f64e60;
  color: #ffff;
  padding: 6px;
  overflow: hidden;
}

.paid__widget {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px;
  background: #f64e60;
  overflow: hidden;
  color: #ffff;
  padding: 20px;
  overflow: hidden;
}

.paid__widget_sales {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 12px;
  background: #f64e60;
  overflow: hidden;
  color: #ffff;
  padding: 10px 15px 10px 15px;
  overflow: hidden;
}
.paid__widget_SOWISE span {
  font-weight: 400;
  color: black;
  display: block;
  font-size: 10px !important;
}

.paid__widget_SOWISE {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px;
  background: #f64e60;
  overflow: hidden;
  padding: 9px 10px 10px 14px; /* TOP RIGHT BOTTOM LEFT */
  overflow: hidden;

}

.widget__right {
  padding-top: 10px;
}

.widget__right p {
  color: #ffff;
  font-weight: 500;
  font-size: 13px !important;
  padding-top: 7px;
}

.widget__right h4 {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.chart__box {
  box-shadow: 0px 2px 6px #2c28281c;
  border-radius: 10px;
  padding-bottom: 10%;
  overflow: hidden;
  height: 380px;
}
.target-label-cumalative-card{
  font-size: 11px;
  font-weight: 500;
}

.chart__box p {
  padding-top: 10px;
  font-size: 11px !important;
  padding-left: 10px;
}

.chart__box h4 {
  font-size: 14px !important;
  padding-left: 10px;
  color: #172b4d;
}

.paid__widget.one {
  background-color: #6993ff;
}

.paid__widget.two {
  background-color: #1bc5bd;
}

.paid__widget.three {
  background-color: #ffa800;
}
.paid__widget.five {
  background-color: #e587f0;
}
.paid__widget.six {
  background-color: #B8D9C9;
}
.paid__widget.seven {
  background-color: #E1CBCB;
}
.paid__widget_sales.one {
  background-color: #6993ff;
}

.paid__widget_sales.two {
  background-color: #1bc5bd;
}

.paid__widget_sales.three {
  background-color: #ffa800;
}
.paid__widget_sales.five {
  background-color: #e587f0;
}
.paid__widget_sales.six {
  background-color: #74d0a3;
}
.paid__widget_sales.seven {
  background-color: #dbb5b5;
}
.paid__widget_SOWISE.one {
  background-color: #6993ff;
}

.paid__widget_SOWISE.two {
  background-color: #1bc5bd;
}

.paid__widget_SOWISE.three {
  background-color: #ffa800;
}
.paid__widget_SOWISE.five {
  background-color: #e587f0;
}

.paid__widget_SOWISE.six {
  background-color: #e0d086;
}

.paid__widget_SOWISE.eight {
  background-color: #a7958e;
}

.paid__widget_New.one {
  background-color: #6993ff;
}

.paid__widget_New.two {
  background-color: #1bc5bd;
}

.paid__widget_New.three {
  background-color: #ffa800;
}


.paid__widget_status {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px;
  background: #f64e60;
  overflow: hidden;
  color: #ffff;
  padding: 10px;
  overflow: hidden;
}

.paid__widget_status.one {
  background-color: #6993ff;
}

.paid__widget_status.two {
  background-color: #1bc5bd;
}

.paid__widget_status.three {
  background-color: #ffa800;
}
.paid__widget_status.five {
  background-color: #e587f0;
}
.paid__widget_status.six {
  background-color: #B8D9C9;
}
.paid__widget_status.seven {
  background-color: #E1CBCB;
}

.widget__right_status {
  padding-top: 0px;
  padding-left: 10px;
}

.dough__main {
  padding-top: 30px;
}

.widget__left {
  position: relative;
}

.target-label {
  position: absolute;
  right: -15px;
  top: -15px;
  background: rgb(91, 1, 114) !important;
  padding: 2px;
  border-radius: 7px;
  width: 90px !important;
  font-size: 10px !important;
}
.secondary-entry-label {
  position: absolute;
  right: -15px;
  top: -15px;
  background: rgb(91, 1, 114) !important;
  padding: 2px;
  border-radius: 7px;
  width: 140px !important;
  font-size: 10px !important;
}

.target-label-cumalative {
  position: absolute;
  right: -15px;
  top: -15px;
  background: rgb(91, 1, 114) !important;
  padding: 2px;
  border-radius: 7px;
  width: 120px !important;
  font-size: 10px !important;
  text-align: center;
  justify-content: center;
}

.target-label-cumalative-bank {
  position: absolute;
  right: -15px;
  top: -15px;
  background: rgb(91, 1, 114) !important;
  padding: 2px;
  border-radius: 7px;
  width: 160px !important;
  font-size: 10px !important;
  text-align: center;
  justify-content: center;
}


.doug__left {
  float: left;
  width: 52%;
  float: left;
  width: 52%;
  padding-top: 25px;
}

.doug__right {
  float: left;
  width: 40%;
  padding-top: 25px;
}

.color__circle {
  display: inline-block;
}

.stat__1 {
  position: relative;
}

.stat__1::before {
  content: "";
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: black;
  position: absolute;
  top: 10px;
  left: 0px;
  border: 3px solid #feb969;
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #feb969;
}

.border__orange {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: black;
  position: absolute;
  top: 10px;
  left: 0px;
  border: 3px solid #2dce98;
}

.border__blue {
  border: 3px solid #1f50b1;
}

.border__red {
  border: 3px solid #f53c56;
}

.dough__list {
  color: #4d4f5c;
  font-size: 11px;
  font-weight: 600;
  margin-left: 3px;
  font-family: "poppins";
}

.list__1 {
  margin-right: 25px;
}

.list__2 {
  margin-right: 15px;
}

.list__3 {
  margin-right: 70px;
}

.list__4 {
  margin-right: 67px;
}

.dough__num {
  font-weight: 500;
}

.widget__left img {
  width: 30px;
}

.widget__left.three img {
  width: 20px;
}

/* DashBoard Main Page Design Ends */

/* login page  */

.form-control:focus {
  color: #000000 !important;
}

.stat__2 {
  position: relative;
}

.stat__2::before {
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #2dce98;
}

.stat__3 {
  position: relative;
}

.stat__3::before {
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #1f50b1;
}

.stat__4 {
  position: relative;
}

.stat__4::before {
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #f53c56;
}

a#kt_login_forgot {
  color: #3699ff !important;
  font-weight: 600;
}

button#kt_login_signin_submit {
  box-shadow: #00000029 !important;
  margin: auto;
}

.heading {
  color: #160637;
  font-size: 30px !important;
}

span.loginText {
  font-weight: 600;
}

a {
  color: #4174aa !important;
  font-size: 12px;
}

img.loginpageLogo {
  width: 180px;
}

.login.login-1 .login-aside {
  height: 100vh;
}

.loginForm input.form-control {
  background: #eef1f5 !important;
  height: 36px !important;
  border: 1px solid #eef1f5;
  box-shadow: 0px 1px 5px #00000010 !important;
}

h1.font-size-h1.heading {
  text-align: center;
  font-weight: 500;
  /* margin-bottom: 20px; */
}

.heading {
  font-size: 22px !important;
}

.overlay__caption {
  background-color: #007bff;
  color: white;
  border-radius: 9px;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.2s;
  position: relative;
  border-radius: 5px;
}

.overlay__caption:hover {
  color: #fff;
  /* box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15); */
}

.overlay__caption:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #007bff;
  opacity: 0;
  transition: all 0.3s, opacity 0.2s;
  transform: scaleY(0);
  transform-origin: bottom;
}

.overlay__caption:hover:before {
  transform: scaleY(1);
  opacity: 0.25;
}

.login.login-1 .login-aside {
  width: 100%;
}

.loginForm input.form-control {
  background: #eef1f5 !important;
  height: 36px !important;
  border: 1px solid #95989a4d;
  box-shadow: 0px 1px 5px #00000029 !important;
}

.loginForm input#formBasicChecbox {
  box-shadow: 0px 3px 5px #00000021 !important;
  border: none !important;
}

.flex-row-fluid.d-flex.flex-column.position-relative.loginPage {
  max-width: 50% !important;
}

.login-aside.d-flex.flex-row-auto.loginBg {
  max-width: 50%;
}

input.form-control.loginFormInput.emailinput.h-auto.py-5.px-6.is-valid {
  width: 300px !important;
}

.login-signin {
  width: 400px;
}

img.loginpageLogo {
  margin: auto;
  display: block;
}

/* Table */

button.btn.approve {
  background: #eee5ff;
  color: #8950fc;
  border-color: #eee5ff;
  font-weight: 500;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2;
}

i.editIcon {
  background: #f3f6f9;
  padding: 8px;
  border-radius: 5px;
  color: #3699ff;
}

i.deleteIcon {
  background: #f3f6f9;
  padding: 8px;
  border-radius: 5px;
  color: #bc0f0f;
}

i.password {
  border-radius: 5px;
  color: #3699ff;
}

/* i.editIcon:hover {
    border: 2px solid #8950fc;
    padding: 8px;
  } */

input.form-check-input.position-static {
  background: #f3f6f9;
}

.circulerBar {
  width: 39px;
}

.voyageTable td {
  color: #464e5f;
  font-size: 13px;
}

.voyageTable th {
  color: #b5b5c3;
  font-size: 12px;
  text-transform: uppercase;
}

h1.tableheading {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  margin-top: 5px;
}

i.tableFilter {
  color: #3699ff;
}

.input-area-add .float-left {
  width: 80%;
}

.input-area-add .float-right {
  width: 20%;
}

.input-area-add input,
.input-area-add select {
  border-radius: 0px !important;
}

.btn-modal-close {
  position: absolute;
  right: -16px;
  padding-right: 20px;
  cursor: pointer;
  top: -10px;
  background: #ffffffed;
  border-radius: 20px;
  width: 36px;
  height: 36px;
  padding: 12px;
  box-shadow: 1px 2px 30px grey;
}

.btn-modal-close i {
  margin: -1px;
  margin-left: 1px;
  margin-top: -2px;
}

.btn-default {
  position: relative !important;
}

.btn-default i {
  width: 9px;
  height: 9px;
  position: absolute;
  right: 10px;
  bottom: 7px;
  font-size: 14px !important;
}

button.btn.btn-default {
  border: 1px solid #e5eaee;
}

.btn.btn-default i {
  color: #d9e0e6;
}



.input-area-add .btn-default,
.input-area-add .btn-default:hover {
  border-radius: 4px !important;
  /* background: white; */
  /* border-color: #ecf0f3; */
  /* border-left: 0px !important; */
  margin-left: -3px;
  background: #fff;
  border: 1px solid #e5eaee !important;
  height: 30px;
  background: #fff !important;
}


.tbl-survey {
  /* width: 74.5% !important; */
  width: 100% !important;
  margin: 10px 0px 10px 0px;
}

.tbl-survey thead {
  background: red !important;
}

.tbl-survey th {
  background: #789dfe38;
}

/* basic form */

.css-1okebmr-indicatorSeparator {
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.css-yk16xz-control {
  background: #fff !important;
  height: 30px !important;
  min-height: 30px !important;
  border: 1px solid #e5eaee !important;
}

.css-1pahdxg-control {
  background: #fff !important;
  height: 30px !important;
  min-height: 30px !important;
  border: 1px solid #e5eaee !important;
}

.css-g1d714-ValueContainer {
  padding: 3px !important;
  /* margin-top: -5px !important; */
}

.aside-menu .menu-nav>.menu-section {
  margin: 0px 0 0 0 !important;
  height: 10px !important;
}

.aside-menu .menu-nav>.menu-item>.menu-link {
  padding: 5px 20px !important;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link {
  min-height: 30px !important;
}

.css-26l3qy-menu {
  padding: 0px !important;
}

.aside-menu .menu-nav>.menu-item>.menu-heading,
.aside-menu .menu-nav>.menu-item>.menu-link {
  min-height: 36px !important;
}

.aside .aside-menu .menu-nav {
  width: auto !important;
  /* width: 1800px; */
}

.css-1laa021-a11yText {
  padding: 0px !important;
}

.css-1wa3eu0-placeholder {
  top: 42% !important;
  font-size: 10px !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  margin-top: -5px !important;
}

.css-1uccc91-singleValue {
  top: 35% !important;
  font-size: 10px !important;
}

.searchInput {
  background: #fff !important;
  height: 30px !important;
  border: 1px solid #e5eaee !important;
  box-shadow: none !important;
}

.searchInput i.flaticon-search {
  padding-left: 5px !important;
}

button.MuiButtonBase-root.MuiIconButton-root.searchPlaceholder {
  padding: 0px !important;
  padding-top: 2px !important;
}

input.MuiInputBase-input {
  margin-left: 10px;
}

.table.table-head-custom thead th,
.table.table-head-custom thead tr {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border-bottom: 1px solid #ecf0f3 !important;
  padding: 8px;
}

.table.table-head-custom tbody th,
.table.table-head-custom tbody tr {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border-bottom: 1px solid #ecf0f3 !important;
  padding: 8px;
}

.table.table-head-custom-print thead tr th,
.table.table-head-custom-print thead tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
}

.table.table-head-custom-print tbody th,
.table.table-head-custom-print tbody tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
}

.user-list-table td,
th {
  border-bottom: 1px solid #ecf0f3 !important;
}

.table {
  margin-bottom: 2px !important;
}

.table.table-head-custom thead td {
  color: #464e5f;
  font-weight: 400 !important;
}

.table.table-head-custom thead th {
  color: #b5b5c3;
  font-weight: 600;
}

.table.table-head-custom-print thead td {
  color: #000;
  font-size: 25px;
  font-weight: 900 !important;
}

.table.table-head-custom-print tbody tr td {
  color: #000;
  font-size: 25px;
  border: 1px solid #000 !important;
  font-weight: 900 !important;
}

.table.table-head-custom-print thead th {
  color: #000;
  font-size: 25px;
  font-weight: 900;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.modal-body {
  padding: 0.75rem;
  padding-top: 0px !important;
}

input[type="file"] {
  border: 1px solid #e5eaee;
  border-radius: 4px;
}

.MuiPaper-rounded {
  border-radius: 6px !important;
}

.custome-datepicker {
  position: relative;
}

i.fas.fa-calendar-alt {
  position: absolute;
  top: 29px;
  right: 20px !important;
  font-size: 10px !important;
}

.page-item.active .page-link {
  background-color: #3699ff;
  border-color: #3699ff;
  color: #fff !important;
  z-index: 0;
  position: absolute;
  color: #abb5be;
  /* left: 249px; */
}

input.date-picker,
input.react-datepicker-ignore-onclickoutside {
  width: 100% !important;
  border-radius: 4px;
  height: 30px;
  border: 1px solid #e5eaee;
  font-size: 10px !important;
  color: #464e5f;
  padding-left: 10px;
  position: relative !important;
}

.loginBg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

button#kt_login_signin_submit {
  width: 142px;
}

/* line chart */

.line-chartsection {
  border-radius: 12px;
}

.pointer {
  cursor: pointer;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.status-list {
  background: aliceblue;
  padding: 4px;
  margin-top: 30px;
}

.float-left.total {
  width: 21%;
}

.float-left.balance {
  width: 16%;
}

/* table form */

.row.mb-5.table-form {
  align-items: center;
}

/* dashboard table */

h1.sales-payment-heading {
  font-size: 20px;
  color: #212121;
}

table.table.table.dashboard-table th,
td {
  font-weight: 600;
  color: #212121 !important;
  text-transform: capitalize;
}

p.dashboard-table-text {
  font-weight: 600;
  background-color: #f3f6f9;
}

.btn.invoice-btn {
  border-color: #789dfe;
  background: #789dfe;
}

.btn.invoice-btn:hover {
  border-color: #789dfe !important;
  background: #789dfe !important;
}

button.btn.text-white.float-right.invoice-btn {
  padding: 5px 10px !important;
  border-radius: 4px !important;
}

p.show-value-text {
  color: #000000 !important;
}

.attachment-file input {
  /* color: #b5b5c3; */
  font-size: 12px !important;
}

::-webkit-file-upload-button {
  background: #fff;
  border: 1px solid #e5eaee;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px !important;
  padding: 5px;
  /* color: #b5b5c3; */
}

/*  */

a._2pGos._3CDiP._2RWmX,
a._2pGos._3CDiP {
  background: #e5e5e5 !important;
  line-height: 10px !important;
  color: #464e5f !important;
  font-weight: 400;
  border-radius: 3px !important;
}

._hsN1w {
  line-height: 10px !important;
  background-color: #789dfe !important;
  border: 1px solid #789dfe !important;
  font-weight: 400;
  border-radius: 3px !important;
  color: #fff !important;
}

._3uApM {
  margin-top: -5px !important;
  margin-left: 25px;
}

h3 {
  font-size: 18px !important;
}

._1hzhf {
  font-size: 14px;
}

._2Jtxm._35Ago ._2kL0S {
  margin-left: -140px;
}

._2Jtxm {
  margin-left: -66px;
}

._1Lo2h {
  width: 109%;
}

/* Certificate create */

.card-header.certificate-cardheader {
  min-height: 0px !important;
}

.certificate-card-body {
  padding: 0rem 2.25rem !important;
}

.header.header-fixed {
  height: 50px !important;
}

.header-fixed.subheader-fixed.subheader-enabled .wrapper {
  padding-top: 54px !important;
}

.custome-addnew-btn {
  height: 29px;
  padding: 5px;
}

/* item list start */

i.item-list-icon {
  font-size: 12px !important;
}

.itemtable td {
  font-weight: 400 !important;
}

i.editIcon.item-list-icon {
  padding: 5px;
  margin-left: 5px;
}

button.item-list-btn {
  height: 33px;
  border-radius: 4px;
  background: #789dfe !important;
  border: 1px solid #789dfe !important;
}

/* item add */

button.item-add-btn {
  border: 1px solid #afafaf !important;
  background: #e4ebff !important;
}

button.item-add-save {
  padding-left: 35px;
  padding-right: 35px;
  background: #1bc5bd !important;
  border: #1bc5bd !important;
  border-radius: 4px !important;
}

table.table.table.item-add-table th,
tr,
td {
  /* border-top: none !important; */
  border-bottom: none !important;
  font-weight: 400 !important;
  
}

table.table.table.item-add-table th {
  /* border-top: none !important; */
  border-bottom: none !important;
  font-weight: 600 !important;
  background: antiquewhite !important;
}
table.table.table.item-add-table1 th {
  /* border-top: none !important; */
  border-bottom: none !important;
  font-weight: 600 !important;
  background: rgb(85, 168, 232) !important;
}
table.table.table.item-add-table2 th {
  /* border-top: none !important; */
  border-bottom: none !important;
  font-weight: 600 !important;
  background: rgb(98, 216, 224) !important;
}
table.table.table.item-add-table3 th {
  /* border-top: none !important; */
  border-bottom: none !important;
  font-weight: 600 !important;
  background: rgb(185, 148, 250) !important;
}

/* booking entry */

.booking-entry-input.form-group {
  margin-left: -13px;
  width: 112% !important;
}

.input-group {
  width: 112% !important;
}

.booking-btn {
  background-color: #007bff !important;
  line-height: 12px !important;
  border-radius: 3px;
}

.MuiPaper-elevation1 {
  box-shadow: none 0px !important;
}

.booking-list-btn {
  background: #fff2d8 !important;
  border: 1px solid #fff2d8 !important;
}

.form-group label {
  font-size: 12px !important;
  margin-bottom: 0px !important;
}

span#inputGroupPrepend {
  height: 30px !important;
  border-left: 0px !important;
}

.MuiPaper-root.searchInput.MuiPaper-elevation1.MuiPaper-rounded {
  box-shadow: none !important;
}

.certificate-add-btn.btn-sm {
  line-height: 1.15 !important;
}

.row.custom-modal {
  width: 1063px;
}

hr.hr-margin {
  margin-top: 8px !important;
}

/* userlist modal */

button.modal-button {
  border: none !important;
  background: none !important;
}

/* cerificate list */

button.certificate-done {
  background: #8ec7ff !important;
  border: #8ec7ff !important;
}

.certificate-due {
  background: #678db2 !important;
  border: #678db2 !important;
}

.certificate-lis-btn {
  padding: 3px 15px !important;
  border-radius: 2px !important;
  width: 75px !important;
  border: none !important;
}

.certificate-edit-icon {
  background: #e7f3fe !important;
  padding: 5px;
  border-radius: 2px;
  color: #3699ff;
  font-size: 11px;
}

.due-days h6 {
  font-size: 11px !important;
  text-transform: uppercase;
  position: absolute;
  top: 6px;
  left: 29px;
}

.due-days {
  position: relative;
}

p.certificate-list-text {
  width: 101px;
  font-size: 12px !important;
  font-weight: bold;
}

.certificate-due-0 {
  background: #ea673e !important;
}

.certificate-due-l-30 {
  background: #8ec7ff !important;
}

.certificate-due-30-60 {
  background: #678db2 !important;
}

.certificate-due-g-60 {
  background: #8af2c0 !important;
}

/* expense addition-deduction */

/* .MuiPaper-root.makeStyles-root-1.MuiPaper-elevation1.MuiPaper-rounded {
  
    box-shadow: none !important;
    border: 1px solid #ccc !important;
} */

/* certificate color picker */

input.color-picker {
  /* margin-bottom: 7px; */
  height: 24px;
  width: 24px;
  border-radius: 3px;
  /* margin-left: 12px; */
}

.card-top-border {
  border-top: 3px solid #3699ff !important;
  border-radius: 0px !important;
}

.card-top-border1 {
  border-top: 3px solid #eb6f09 !important;
  border-radius: 0px !important;
}

.card-bottom-border {
  border-bottom: 3px solid #eb6f09 !important;
  border-radius: 0px !important;
}

.card-top-border-success {
  border-top: 3px solid #1bc5bd !important;
  border-radius: 0px !important;
}

.card-top-border-info {
  border-top: 3px solid #3699ff !important;
  border-radius: 0px !important;
}

.card-top-border-error {
  border-top: 3px solid #ff9da7 !important;
  border-radius: 0px !important;
}

.MuiInputBase-root.custome-purchase-search {
  width: 85%;
}

button.searchPlaceholder.purchaseSearch {
  right: 21px !important;
}

.purchaseSearch {
  margin-left: 20px !important;
}

.custome-border-left {
  min-width: 14%;
}

.custome-border-design {
  width: 100%;
  border-bottom: 2px solid lightgray;
  margin-left: 13px;
  margin-bottom: 10px;
}

/* navbar */

.aside-enabled.subheader-fixed .subheader {
  left: 255px;
}

.header-fixed.subheader-fixed .subheader {
  position: fixed;
  height: 54px;
  top: 50px !important;
  left: 0;
  right: 0;
  transition: top 0.3s ease;
  z-index: 95;
  box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
  background-color: #fff;
  border-top: 1px solid #ebedf3;
  margin: 0;
}

.subheader {
  display: flex;
  align-items: center;
}

.dashboard-cardsection {
  padding-top: 43px !important;
}

.dashboard-navbar-left {
  width: 50% !important;
  float: left !important;
}

.dashboard-navbar-right {
  float: right !important;
  width: 47% !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.card.card-custom>.card-header {
  min-height: 45px !important;
}

/* .card.card-custom > .card-body {
  padding: 2rem 2rem !important;
} */

/* .aside {
  width: 230px !important;
} */

.header-fixed.subheader-fixed .header {
  background: #3699ff;
}

.top-search-bar {
  background: #0000001a;
}

.top-search-bar:hover {
  background: #007bff !important;
}

@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover) .brand {
    display: none !important;
  }
}

input.bulk-file-input {
  display: block;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

.searchPlaceholder:hover {
  background: none !important;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

i.flaticon-search {
  margin-bottom: 0px !important;
}

.supplier-search {
  position: relative !important;
}

button.searchPlaceholder {
  position: absolute !important;
  right: 8px !important;
}

.viewIcon {
  background: #e7f3fe;
  padding: 5px;
  border-radius: 5px;
  color: #3699ff;
  font-size: 10px;
}

.custom-border {
  border-bottom: 2px solid #ecf0f3 !important;
  width: 107%;
  margin-left: -47px;
}

.supplier-table td {
  padding: 5px 0px !important;
}

i.fas.fa-trash-alt.viewIcon {
  margin-left: 15px;
}

h5.supplier-detail-date {
  font-size: 13px !important;
}

h6.supplier-detail-text {
  font-size: 11px !important;
}

h6.supplier-modal-header {
  font-weight: 900 !important;
  color: #212529 !important;
  font-size: 16px !important;
}

.supplier-info p {
  font-weight: 500 !important;
  color: #212529 !important;
  line-height: 1.5 !important;
}

.quotation-table td {
  padding: 10px 0px !important;
}

/* employee-list page */
.employee-list-input {
  height: 30px !important;
  padding: 0px !important;
}

.employee-signin {
  margin-left: -11px;
}

.float-left.booking-text {
  width: 8%;
}

.booking-text.float-right {
  width: 92%;
}

.float-left.booking-text.one {
  width: 10%;
}

.booking-text.float-right.one {
  width: 90%;
}

.float-left.booking-text.two {
  width: 16%;
}

.booking-text.float-right.two {
  width: 84%;
}

.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-clean:focus:not(.btn-text),
.btn.btn-clean.focus:not(.btn-text) {
  background-color: transparent !important;
}

.certificate-list-table th {
  font-size: 10px !important;
  font-weight: 400 !important;
}

.certificate-list-table td {
  font-size: 11px !important;
}

h1.headerText {
  font-size: 13px !important;
}

.custome-border {
  position: relative;
}

.custome-border::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 80%;
  background-color: #000000;
  left: 0;
  top: 0;
}

/* booking entry */
.float-left.Rate-commision {
  width: 13%;
  margin-left: 11px;
}

.float-right.Rate-commision {
  width: 85%;
  margin-top: -6px;
}

.float-left.Rate-commision.one {
  width: 7%;
}

.float-right.Rate-commision.one {
  width: 91%;
}

.float-left.Rate-commision.two {
  width: 8%;
}

.float-right.Rate-commision.two {
  width: 90%;
}

input[type="file"] {
  width: 100%;
}

label.form-check-label {
  font-size: 14px;
}

label.form-control.criteriaOptionsInput {
  padding-bottom: 43px;
}

.invoice-price-right-text {
  text-align: right;
  padding: 22px;
  width: 76%;
  font-weight: 900;
  font-size: 16px;
}

/* @media print {
  body { font-size: 20pt }
  td{
    font-size: 20px;
  }
}
@media screen {
  body { font-size: 30px }
  td{
    font-size: 20px;
  }

}
@media screen, print {
  body { line-height: 1.2 }
  td{
    font-size: 20px;
  }
} */

@media print {

  #printDiv,
  #printDiv * {
    visibility: visible;
  }

  #printDiv {
    position: absolute;
    left: 0;
    top: 0;
  }
}

/* @media print {
  body * {
    visibility: hidden;
  }
  #printDiv,
  #printDiv * {
    visibility: visible;
  }
  #printDiv {
    position: absolute;
    left: 0;
    top: 0;
  }
  #printDivm,
  #printDivm * {
    visibility: visible;
  }
  #printDivm {
    padding: 80px;
    position: absolute;
  }
} */
th.divisionPos {
  position: absolute;
  width: 15%;
}

th.regionPos {
  position: absolute;
  width: 7%;
  left: 367px;
}

input.fromStyle.form-control {
  height: 30px;
}

.refreshIcon {
  font-size: 24px;
}

.balanceleft {
  float: left;
  width: 50%;
  height: 21px;
  font-size: 14px;
}

.balanceleftmodified {
  float: left;
  width: 23%;
  height: 21px;
  font-size: 14px;
}

.markDetails {
  background-color: #8530161f !important;
}

.btn-group.SalesType {
  height: 40px;
}

label.btn.btn-outline-primary.salsRadious {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.profileScreen.card {
  padding: 40px;
}

.aprovedRow span {
  float: left;
  width: 14%;
}

p.aprovedRow {
  position: relative;
}

span.enroll {
  position: absolute;
  right: 30%;
}

span.total {
  position: absolute;
  right: -83px;
}

span.total {
  position: absolute;
  right: -31px;
}

span.quantity {
  position: absolute;
  right: 7%;
}

span.price {
  position: absolute;
  right: 13%;
}

span.soName {
  position: absolute;
  right: 24%;
}

p.aprovedRow {
  position: relative;
  padding: 21px;
  border: 1px solid #ddd;
  background-color: #fffcf5;
}

span.papproved {
  position: absolute;
  left: 40%;
}

span.psoName {
  position: absolute;
  right: 40%;
}

span.pprice {
  position: absolute;
  right: 23%;
}

span.pquantity {
  position: absolute;
  right: 16%;
}

span.papprovedQuntity {
  position: absolute;
  right: 29%;
}

span.pptotal {
  position: absolute;
  right: 8%;
}

.customepadding {
  height: 41px;
}

.dateWiseReport {
  width: 4000px;
}

.tblFooterback {
  background: #f7f7f7 !important;
}

strong.blue {
  font-size: 12px;
  color: #443b3d;
}

/* .pvtAxisContainer, .pvtVals {
  border: 1px solid #a2b1c6;
  background: #f2f5fa;
  padding: 5px;
  min-width: 20px;
  min-height: 20px;
  display: none;
}
.pvtRenderers {
  padding-left: 5px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: none;
}
table.pvtTable thead tr th, table.pvtTable tbody tr th {
  background-color: #fff !important;
  border: 1px solid #c8d4e3 !important;
  font-size: 8pt;
  padding: 5px;
}
.breadBurnPivot {
  width: 200% !important;
} */
.achiveLayer span {
  display: block;
  font-size: 12px;
  line-height: 20px;
  color: #050f26;
  font-weight: 600;
}

/* td.d1 {
  width: 236px;
} */

/* datewise */
/* th.enroll {
  position: absolute;
  right: 87%;
}
thead.dateThe {
  position: relative;
}
th.product_code {
  position: absolute;
  right: 87%;
}
th.soName {
    position: absolute;
    right: 89%;
}
th.product_name {
  position: absolute;
  right: 83%;
}
th.field0 {
  position: absolute;
  right: 80%;
}

th.field1 {
  position: absolute;
  right: 77%;
}
th.field2 {
  position: absolute;
  right: 74%;
}
th.field3 {
  position: absolute;
  right:71%;
}
th.field4 {
  position: absolute;
  right: 69%;
}
th.field5 {
  position: absolute;
  right: 66%;
}
th.field6 {
  position: absolute;
  right: 63%;
}
th.field7 {
  position: absolute;
  right: 60%;
}
th.field8 {
  position: absolute;
  right: 57%;
}
th.field9 {
  position: absolute;
  right: 54%;
}
th.field10 {
  position: absolute;
  right: 51%;
}
th.field11 {
  position: absolute;
  right: 48%;
}
th.field12 {
  position: absolute;
  right: 45%;
}
th.field13 {
  position: absolute;
  right: 42%;
} */

.validation-symbol {
  color: red !important;
  font-size: 10px !important;
}

.validation-no-offer {
  color: black !important;
  font-size: 10px !important;
}

.changeLayout {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
}

.bg-yellow {
  background: #FFA800;
  display: block;
  width: 64%;
  margin-right: 13px;
  padding: 6px;
}

.invoiceInfo {
  flex-basis: 8%;
  font-size: 21px;
  font-family: 'Font Awesome 5 Free !important';
}

.bg-yellow2 {
  background: #FFA800;
  flex-basis: 25%;
}

p.invoiceTo {
  font-family: 'Font Awesome 5 Free';
  font-weight: 700 !important;
  font-size: 21px !important;
}

.invoice-detail {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Font Awesome 5 Free';
}


/* soSecondarySales by efaj */

.Secondary_so_information {

  font-weight: 800;

}

.gutter-b {
  margin-bottom: 10px !important;
}

/* soSecondarySales by efaj */

.dashpic {
  height: 25px;
  width: 25px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.symbol.symbol-60 .symbol-label {
  width: 35px !important;
  height: 35px !important;
}

.dash-AI-akij img {
  align-items: center;
  height: 85px;
  width: 160px;
  padding: 10px;
}

/* .dash-AI-akij img{
  align-items: center;
  height: 140px;
  width: 140px;
} */
.cTable {
  height: 100%;
}

/* .table-head-custom tr {
  width: 100%;
  display: inline-table;
  height:60px;
  table-layout: fixed;
    
  }
  
  .table-head-custom{
   height:1000px;
   display: block;
  }
  .table-head-custom tbody{
    overflow-X: scroll;
    height: 1000px;
    width: 100%;
    position: absolute;
  } */
.stickyTablecus {
  /* position: sticky; */
  overflow-X: auto;
  height: 500px;
}

.stickyTablecus table {
  width: 100%;
  table-layout: fixed;
}

.stickyTablecus table th {
  top: 0;
  position: sticky;
  background-color: #fff;
}

/* .demo table{
  overflow-X: scroll;
  width: 100%;
  background-color: #fff;
}
.demo table th {
  top:0;
  background-color: #fff;
} */
.stickyTable {
  overflow-x: auto;
  height: 500px;
}

.stickyTable table {
  width: 150%;
  table-layout: fixed;
}

.stickyTable table th {
  top: 0;
  position: sticky;
  background-color: #fff;
}

.shortstickyTable {
  overflow-x: auto;
  height: 500px;
}

.shortstickyTable table {
  width: 100%;
  table-layout: fixed;

}

.shortstickyTable table th {
  top: 0;
  position: sticky;
  background-color: #fff;
}

.rmdp-input {
  height: 30px !important;
  width: 240px !important;
}

.collectionDateText {
  font-size: 114px;
}

.udevelopmentText {
  font-size: 17px !important;
  font-weight: 800;
}

.udevelopmentText_bg {
  box-shadow: 0px 2px 2px #0000001c;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  color: black;
  padding: 4px;
  overflow: hidden;
  position: relative;
  text-align: center;
  background-color: rgb(252, 237, 212);
}

.ucase {
  text-transform: lowercase !important;
}

.callITsupport {
  padding-top: 30px;
  font-family: 'Ubuntu', sans-serif;
  font-size: 25px;
  font-weight: bold;
  color: #02a423;
  text-align: center;
  letter-spacing: 0px;
  font-family: Noto Sans, sans-serif;
}

.cardcustomheight {
  height: 400px;
}

.dashboardTopCardDataColor {
  color: #ff4401;
}

.loadingcustom {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .piedesign{
  width: 370px;
} */
.totalCategorytext {
  color: #c33d08;
}

.Acheivement {
  height: 310px !important;
}

.achi {
  height: 200px !important;
}

.tblbor {
  border: 1px solid black;
  border-collapse: collapse;
}

.printtblcustom {
  padding: 80px;
}

.textcustom thead tr th {
  font-size: 40px;
}

.textcustom {
  font-size: 40px !important;
}

.tecus th {
  font-size: 40px;
}



@media (min-width: 320px) {
  .stickyTable table {
    width: 150%;
    table-layout: auto;
  }

  .shortstickyTable table {
    width: 100%;
    table-layout: auto;

  }

}

#kt_header {
  background-color: #3699ff;
}

.spineercolor {
  color: #082d54;
}

@page {
  size: auto;
  margin: 20mm;
}


#image-uploader {
  display: flex;
  padding: 5px 8px;
  border: 3px solid;
  border-radius: 0px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  color: #ffc872;
  align-items: center;
  justify-content: center;

}

/* input:hover{
    transform: scale(1.04);
  }
  input.active{
    background-color: #ffc872;
    color: #242424;
  }
  input span{
    font-weight: normal;
  } */


/* .....progress bar ...... */

/* progress bar - 1*/

.vertical_bar {
  margin-top: 3px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 3px;
  background: #fff;
  color: black;
  padding: 3px;
  position: relative;
  text-align: center;
}

.progress {
  background-color: #f5f5f5;
  border-radius: 3px;
  box-shadow: none;
}

.progress.vertical {
  position: relative;
  width: 50px;
  height: 325px;
  display: inline-block;
  border: 1px solid #7d4b10;
  ;

}

.progress {
  height: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress.vertical>.progress-bar {
  width: 100% !important;
  position: absolute;
  bottom: 0;
}

.progress-bar {
  background-color: #3a2205;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #733b09 !important;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

/* progress bar - 2*/


.vertical_bar2 {
  margin-top: 3px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 3px;
  background: #fff;
  color: black;
  padding: 3px;
  position: relative;
  text-align: center;
}

.progress2 {
  background-color: #f5f5f5;
  border-radius: 3px;
  box-shadow: none;
}

.progress2.vertical2 {
  position: relative;
  width: 50px;
  height: 325px;
  display: inline-block;
  border: 1px solid #0b8794;

}

.progress2 {
  height: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress2.vertical2>.progress-bar2 {
  width: 100% !important;
  position: absolute;
  bottom: 0;
}

.progress-bar2 {
  background-color: #733b09;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.progress-bar2 {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #0b8794;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.vertical_bar_bottom1 {
  box-shadow: 0px 3px 6px #2c28281c;
  border: solid rgb(99, 74, 4) 1px;
  /* border-radius: 3px; */
  background: white;
  color: rgb(99, 74, 4);
  padding: 3px;
  position: relative;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  margin-top: 0 !important;
}

.vertical_bar_bottom2 {
  box-shadow: 0px 3px 6px #2c28281c;
  border: solid #0b8794 1px;
  /* border-radius: 3px; */
  background: white;
  color: rgb(99, 74, 4);
  padding: 3px;
  position: relative;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  margin-top: 0 !important;
}



/* money */
.collColor {
  color: #A2D6A2;
}
.money {
  box-shadow: 0px 3px 6px #2c28281c;
  background: rgb(255, 255, 255);
  padding: 0px 0px;
  text-align: center;
  height: 450px;
  border-radius: 3px;


}

.money span {
  font-weight: 700;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}

.sub_card {
  border-bottom: 1px solid #f7f7f7;
  margin: 8px;
}

.text-1st {

  font-size: 30px;
  font-weight: 200;
  color: #7d4b10;
  text-align: center;
  letter-spacing: 0px;
  /* text-shadow: 0px 0px 0px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(255,255,255,0.3); */
  font-family: fantasy;
}

.text-secondary-money {
  font-size: 30px;
  font-weight: 200;
  text-align: center;
  letter-spacing: 0px;
  /* text-shadow: 0px 0px 0px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(255,255,255,0.3); */
  font-family: fantasy;
  color: #0b8794;
}

.text-secondary-money2 {
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  letter-spacing: 0px;
  /* text-shadow: 0px 0px 0px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(255,255,255,0.3); */
  font-family: fantasy;
  color: #7d4b10;
}

.text-secondary-money3 {
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  letter-spacing: 0px;
  /* text-shadow: 0px 0px 0px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(255,255,255,0.3); */
  font-family: fantasy;

}

.text-1st2 {
  font-size: 30px;
  font-weight: bold;
  color: #17B169;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  font-family: fantasy;
}

.acheived {
  color: #7d4b10 !important;
  font-size: 30px !important;
}

span.acheived {
  font-size: 14px !important;
}

/* .text-2nd{
  font-family: 'Ubuntu', sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #174767;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(255,255,255,0.3);
  font-family: 'Times New Roman', serif;
} */

.text-2nd {
  font-size: 30px;
  font-weight: 200;
  color: #11e7b5;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  font-family: fantasy;
}

.text-4th {
  font-size: 30px;
  font-weight: 200;
  color: #17b169;
  text-align: center;
  letter-spacing: 0px;
  /* text-shadow: 0px 0px 0px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(255,255,255,0.3); */
  font-family: fantasy;
}

/* .text-3rd{
  font-family: 'Ubuntu', sans-serif;
  font-size: 27px;
  font-weight: bold;
  color: #ee0b0b;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(255,255,255,0.3);
  font-family: 'Monospace', Lucida;

} */
.text-3rd {
  font-size: 30px;
  font-weight: 200;
  color: #3C4A55;
  text-align: center;
  letter-spacing: 0px;
  /* text-shadow: 0px 0px 0px rgba(0,0,0,0.3), 
    30px -4px 10px rgba(0,0,0,0); */
  font-family: fantasy;

}

.totaltargettext {
  color: #952803;
  font-size: 12px !important;
}

.dashboardCart {
  background-color: #fdfffe !important;
}

.sms {
  background-color: #f9f9f4 !important;
}
.single-sms {
  background-color: #E2F3E1 !important;
}

.cusheight {
  height: 31px !important;
}

.moneyheading1 {
  font-size: 15px !important;
  color: #7d4b10;
  font-weight: 600;
}

.moneyheading2 {
  font-size: 15px !important;
  color: #0b8794;
  font-weight: 600;
}

/* barchart */
/* .cuswidth{
  width: 770px;
 } */
.dailysales {
  font-size: 20px !important;
  color: #fff;
}

.borderTableims.table-head-custom thead th,
.borderTableims.table-head-custom thead tr {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 8px;
}

.borderTableims.table-head-custom tbody th,
.borderTableims.table-head-custom tbody tr td {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 8px;
}

.borderTableims.table-head-custom-print thead tr th,
.borderTableims.table-head-custom-print thead tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
}

.borderTableims.table-head-custom-print tbody th,
.borderTableims.table-head-custom-print tbody tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
}

.user-list-table td,
th {
  border: 1px solid #ecf0f3 !important;
}

.borderTableims {
  margin-bottom: 2px !important;
}

.borderTableims.table-head-custom thead td {
  color: #4f5f46;
  font-weight: 400 !important;
}

.borderTableims.table-head-custom thead tr th {
  color: #000 !important;
  font-weight: 600 !important;
}

.borderTableims.table-head-custom-print thead td {
  color: #000;
  font-size: 25px;
  font-weight: 900 !important;
}

.borderTableims.table-head-custom-print tbody tr td {
  color: #000;
  font-size: 25px;
  border: 1px solid #000 !important;
  font-weight: 900 !important;
}

.table.table-head-custom-print thead th {
  color: #000;
  font-size: 25px;
  font-weight: 900;
}


/* daily basis */
.dailybasistable.table-head-custom thead th,
.dailybasistable.table-head-custom thead tr {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 8px;

}

.dailybasistable.table-head-custom tbody th,
.dailybasistable.table-head-custom tbody tr td {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 8px;

}

.dailybasistable.table-head-custom-print thead tr th,
.dailybasistable.table-head-custom-print thead tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;

}

.dailybasistable.table-head-custom-print tbody th,
.dailybasistable.table-head-custom-print tbody tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;

}

.dailybasistable {
  margin-bottom: 2px !important;
}

.dailybasistable.table-head-custom thead td {
  color: #000 !important;
}

.dailybasistable.table-head-custom thead th {
  color: #000 !important;
  font-weight: 600;

}

.dailybasistable.table-head-custom-print thead td {
  color: #000 !important;
  font-size: 25px;
  font-weight: 900 !important;
}

.dailybasistable.table-head-custom-print tbody tr td {
  color: #000 !important;
  font-size: 25px;
  border: 1px solid #000 !important;
  font-weight: 900 !important;

}

.table>:not(:first-child) {
  border-top: none !important;
}

/* daily basis */

.paddingremove {
  padding-right: 1px !important;
  padding-left: 1px !important
}
.padding2px {
  padding-right: 3px !important;
  padding-left: 3px !important;
  padding-bottom: 6px !important;
}

.paddingremoveAll {
  padding-right: 0px !important;
  padding-left: 0px !important
}

.array.arytext {
  text-align: right !important;
  position: absolute;
}

.arytext {
  font-weight: 800;
}

button.arrayButton.btn.btn-link {
  padding: 0;
  text-decoration: none;
}

.dailysalesfont {
  font-family: fantasy;
  color: #f7f7f7;
}

.moneyacheived {
  color: #17b169;
}

.postisionAb {
  position: absolute;
  top: 26px;
  right: 8px;
}

.positionAssigned {
  position: relative;
}

table.pvtTable tbody tr th,
table.pvtTable thead tr th {
  background-color: #ebf0f8 !important;
  border: 1px solid #c8d4e3 !important;
  font-size: 8pt !important;
  padding: 5px !important;
}

.cuswidth3 {
  width: 345px;
  text-align: center !important;
  justify-content: center !important;
  margin: auto;
}


/* demotabledesign */
.demotable thead th,
.demotable thead tr {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 8px;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

.demotable tbody th,
.demotable tbody tr td {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border: 1px solid #000 !important;
  padding: 8px;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  /* font-weight: 600 !important; */


}

.demotable.table-head-custom-print thead tr th,
.demotable.table-head-custom-print thead tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-weight: 500 !important;


}

.demotable.table-head-custom-print tbody th,
.demotable.table-head-custom-print tbody tr {
  font-size: 25px;
  border: 1px solid #000 !important;
  padding: 10px;
  height: 20px;
  font-family: Verdana !important;
  font-weight: 500 !important;
}

.demotable {
  margin-bottom: 2px !important;
}

.demotable thead td {
  color: #000 !important;

}

.demotable thead th {
  color: #000 !important;
  font-weight: 600;

}

.demotable.table-head-custom-print thead td {
  color: #000 !important;
  font-size: 25px;
  font-weight: 900 !important;
}

.demotable.table-head-custom-print tbody tr td {
  color: #000 !important;
  font-size: 25px;
  border: 1px solid #000 !important;
  font-weight: 900 !important;

}

.scr {
  position: sticky;
}


/* demotabledesign */
.carddemo {

  margin-top: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #fff;
  padding: 10px;
  border-radius: 13px;
  overflow: hidden;
  color: black;
  overflow: hidden;
  position: relative;
  text-align: center;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
    background: rgb(217, 153, 153);
  }

  50% {
    opacity: 0.5;
    background: rgb(166, 211, 159);
  }

  100% {
    opacity: 1;
    background: rgb(233, 194, 153);
  }
}

.columns1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column1 {
  margin-top: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  padding: 10px;
  border-radius: 13px;
  overflow: hidden;
  color: #fff;
  overflow: hidden;
  position: relative;
  text-align: center;
  flex-basis: calc(33.33% - 10px);
  margin-bottom: 10px;
}

.column1color {
  background: rgb(210, 203, 127);
}

.column2color {
  background: rgb(127, 210, 159);
}

.column3color {
  background: rgb(134, 127, 210);
}

.aaa {
  position: relative;
}

.sss {
  position: absolute;
}

.top_card1 {
  margin-top: 5px;
  box-shadow: 0px 3px 6px #2c28281c;
  background: #f7f7f7;
  padding: 10px;
  border-radius: 13px;
  overflow: hidden;
  color: black;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.acheived {
  font-weight: 600;
  color: #806000;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  font-family: fantasy;
}

span.acheived {
  font-size: 14px !important;
}

.locationMother {
  /* position: relative !important; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.location {
  color: #94520f;
}

.locationPosition {
  position: absolute;
}

.txtwidth {
  font-weight: 550;
}

.totaltarget {
  color: #733f03;
}

.rdtcolor {
  color: rgb(151, 110, 63);
}

.totaltargetSec {
  color: #0b8794;
}

.fontfy {
  font-family: 'poppins' !important;
}

.btn-light-brawn {
  background: #ddaf09 !important;
  color: white !important;
}

.vertical_bar_bottom_color_1 {
  color: #ffff;
  background-color: #7d4b10;
}

.vertical_bar_bottom_color_2 {
  color: #ffff;
  background-color: #0b8794;
}

.button-21 {
  align-items: center;
  appearance: none;
  background-color: #3EB2FD;
  background-image: linear-gradient(1deg, #4F58FD, #149BF3 99%);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd, sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s, background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-21:active,
.button-21:focus {
  outline: none;
}

.button-21:hover {
  background-position: -20px -20px;
}

.button-21:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}

.button-62 {
  background: linear-gradient(to bottom right, #484787, #059c97);
  border: 0;
  border-radius: 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.7;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-62:hover,
.button-62:focus {
  background-color: #16437E;
  color: #ffffff;
}

.button-62:active {
  background: #eea90a;
  color: #fff;
}

.button-62:disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  color: #fff;
}

.button-010 {
  background: linear-gradient(to bottom right, #80e6bb, #32c2bd);
  border: 0;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 2.2;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-010:hover,
.button-010:focus {
  background-color: #16437e;
  color: #ffffff;
}

.button-010:active {
  background: #09223b;
  color: #fff;
}

.button-010:disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
}

.button-0101 {
  border: 2px solid #161717;
  border-radius: 12px;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 2.2;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  color: #000;
}

.button-621 {
  background: linear-gradient(to bottom right, #484787, #059c97);
  border: 0;
  border-radius: 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.4;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-621:hover,
.button-621:focus {
  background-color: #16437E;
  color: #ffffff;
}

.button-621:active {
  background: #050f26;
  color: #fff;
}

.button-621:disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  color: #fff;
}

.clickHere {
  font-size: 11px !important;
  color: rgb(228, 228, 228);
  margin-right: 1px;
}

.toparrow {
  margin-right: -2px !important;
  margin-top: 1px;
  color: rgb(224, 240, 9);
}

.wpassed {
  color: #8E7A7B;
  font-size: 30px;
  font-weight: 200;
  text-align: center;
  letter-spacing: 0;
  font-family: fantasy;
}

.clickHeremobile {
  font-size: 11px !important;
  font-weight: 300;
  font-family: "Poppins";
  color: #fff;
  margin-right: 1px;
}

.toparrowMobile {
  margin-right: -3px !important;
  margin-top: 1px;
  color: rgb(224, 240, 9);
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blink-text {
  animation: blink 1s linear infinite;
}

.top_card31 {
  background-image: url('/media/misc/hi.jpg');
  background-size: auto;
  background-position: center;
}

.bg-color-secondary {
  background-color: #80b5ee !important;
}

/* PivotTableUI modified */
td.pvtAxisContainer.pvtHorizList.pvtCols {
  display: none;
}

td.pvtAxisContainer.pvtUnused.pvtVertList {
  display: none;
}

td.pvtAxisContainer.pvtVertList.pvtRows {
  display: none;
}

td.pvtRenderers {
  display: none;
}

td.pvtVals {
  display: none;
}

/* PivotTableUI modified */

.custom-pivot-table .pvtAxisContainer,
.custom-pivot-table .pvtRowAxis,
.custom-pivot-table .pvtColAxis {
  display: none;
}

.yellow-card {
  background-color: yellow;
}

.txtsize {
  font-size: 15px !important;
  font-weight: 500;
}

.backcolor {
  background: #b0c1d2;
}
.backcolor3{
  background: #b0c1d2 !important;
}



.cardtop {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
}

.circle-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: #f8f8f8;
  border-radius: 50%;
  margin-bottom: 20px;
  overflow: hidden;
}

.circle-card .logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.circle-card .content {
  text-align: center;
}

.circle-card h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.circle-card p {
  color: #777;
}

.gocYik {
  background: rgb(254, 246, 232) !important;
}

.card1 {
  background: radial-gradient(#f9f9f9 2px, transparent 2px), #d0eee5;
  background-size: 20px 20px;
}

.card2 {
  background: radial-gradient(#f9f9f9 2px, transparent 2px), #d4ecfa;
  background-size: 20px 20px;
}

.card3 {
  background: radial-gradient(#f9f9f9 2px, transparent 2px), #f3dcf5;
  background-size: 20px 20px;
}

/* .card4 {
  background: radial-gradient(#f9f9f9 2px, transparent 2px), #e0e0e0;
  background-size: 20px 20px;
} */

.paddingRightRemove {
  padding-right: 0px !important;
}

.paddingLeftRemove {
  padding-left: 0px !important;
}

.imgcard {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.imgcard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/media/bg/bakeman_time.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.2;
  /* Adjust the opacity as desired */
  pointer-events: none;
  z-index: -1;
}

.factoryimgcard {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.factoryimgcard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/media/bg/factory.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.2;
  /* Adjust the opacity as desired */
  pointer-events: none;
  z-index: -1;
}

.hrimgcard {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.hrimgcard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/media/bg/akij.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.2;
  /* Adjust the opacity as desired */
  pointer-events: none;
  z-index: -1;
}

/* card_box */

.container_Premium {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: top;
  -ms-flex-align: top;
  align-items: top;
  -webkit-box-pack: top;
  -ms-flex-pack: top;
  justify-content: top;
}

.card_box {
  width: 200px;
  height: 150px;
  border-radius: 20px;
  background: linear-gradient(170deg, rgba(58, 56, 56, 0.623) 0%, rgb(31, 31, 31) 100%);
  position: relative;
  -webkit-box-shadow: 0 25px 50px rgba(0, 0, 0, 0.55);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.55);
  cursor: pointer;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.card_box:hover {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.card_box span {
  position: absolute;
  overflow: hidden;
  width: 150px;
  height: 150px;
  top: -10px;
  left: -10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.card_box span::before {
  content: 'HR';
  position: absolute;
  width: 150%;
  height: 40px;
  background-image: linear-gradient(45deg, #ff6547 0%, #ffb144 51%, #ff7053 100%);
  -webkit-transform: rotate(-45deg) translateY(-20px);
  -ms-transform: rotate(-45deg) translateY(-20px);
  transform: rotate(-45deg) translateY(-20px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
}

.card_box span::after {
  content: '';
  position: absolute;
  width: 10px;
  bottom: 0;
  left: 0;
  height: 10px;
  z-index: -1;
  -webkit-box-shadow: 140px -140px #cc3f47;
  box-shadow: 140px -140px #cc3f47;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
}

/* card_box */
/* card_box_Audit */

.card_box_Audit {
  width: 200px;
  height: 150px;
  border-radius: 20px;
  background: linear-gradient(170deg, rgba(58, 56, 56, 0.623) 0%, rgb(31, 31, 31) 100%);
  position: relative;
  -webkit-box-shadow: 0 25px 50px rgba(0, 0, 0, 0.55);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.55);
  cursor: pointer;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.card_box_Audit:hover {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.card_box_Audit span {
  position: absolute;
  overflow: hidden;
  width: 150px;
  height: 150px;
  top: -10px;
  left: -10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.card_box_Audit span::before {
  content: 'AUDIT';
  position: absolute;
  width: 150%;
  height: 40px;
  background-image: linear-gradient(45deg, #FF512F 0%, #d434ac 51%, #FF512F 100%);
  -webkit-transform: rotate(-45deg) translateY(-20px);
  -ms-transform: rotate(-45deg) translateY(-20px);
  transform: rotate(-45deg) translateY(-20px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
}

.card_box_Audit span::after {
  content: '';
  position: absolute;
  width: 10px;
  bottom: 0;
  left: 0;
  height: 10px;
  z-index: -1;
  -webkit-box-shadow: 140px -140px #cc3f47;
  box-shadow: 140px -140px #cc3f47;
  background-image: linear-gradient(45deg, #FF512F 0%, #0c1cca 51%, #FF512F 100%);
}

/* card_box_Audit */

.btn-modal-close_dashboard {
  position: absolute;
  right: -6px;
  padding-right: 20px;
  cursor: pointer;
  top: -7px;
  background: #ffffffed;
  border-radius: 20px;
  width: 36px;
  height: 36px;
  padding: 12px;
  box-shadow: 1px 1px 10px grey;
  z-index: 1;
}

.btn-modal-close_dashboard i {
  margin: -1px;
  margin-left: 1px;
  margin-top: -2px;
}

.custom-modal .modal-content {
  border-radius: 0;
}

/* For Product Wise Target */

.table-container {
  max-height: 500px; /* Set the desired height for the table body */
  overflow-y: auto; /* Enable vertical scrolling */
}

.table-container thead {
  position: sticky;
  top: 0;
  background-color: #f8f9fa; /* Set the desired background color for the fixed header */
  z-index: 1; /* Ensure the header is above the table body */
  vertical-align: middle;
}

#datatable th {
  text-align: center;
  vertical-align: middle;
}


/* For Product Wise Target */

/* button pdf */

.button-pdf {
  background: linear-gradient(to bottom right, #8787b9, #9190c2);
  border: 0;
  border-radius: 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.4;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-pdf:hover,
.button-pdf:focus {
  background-color: #16437E;
  color: #ffffff;
}

.button-pdf:active {
  background: #050f26;
  color: #fff;
}

.button-pdf:disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  color: #fff;
}
/* button pdf */

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.pagination a {
  color: white !important;
  float: left;
  padding: 2px 12px;
  text-decoration: none;
  transition: background-color 0.3s;
  background-color: #857f99;
  margin-right: 2px;
  border-radius: 5px;
}

.pagination a.active {
  background-color: lightseagreen;
  border-radius: 5px;
  color: white !important;
  font-weight: 600;
}

.pagination a:hover:not(.active) {
  background-color: #9176e8;
  border-radius: 5px;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .pagination {
    flex-wrap: wrap;
  }

  .pagination a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
}


/* From uiverse.io by @satyamchaudharydev */
/* removing default style of button */

.searchBox .form button {
  border: none;
  background: none;
  color: #8b8ba7;
}
/* styling of whole input container */
.searchBox .form {
  --timing: 0.3s;
  --width-of-input: 206px;
  --height-of-input: 40px;
  --border-height: 2px;
  --input-bg: #fff;
  --border-color: #2f2ee9;
  --border-radius: 0px;
  --after-border-radius: 1px;
  position: relative;
  width: var(--width-of-input);
  height: var(--height-of-input);
  display: flex;
  align-items: center;
  padding-inline: 0.8em;
  border-radius: var(--border-radius);
  transition: border-radius 0.5s ease;
  background: var(--input-bg,#fff);
}
/* styling of Input */
.searchBox .input {
  font-size: 0.9rem;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding-inline: 0.5em;
  padding-block: 0.7em;
  border: none;
}
/* styling of animated border */
.searchBox .form:before {
  content: "";
  position: absolute;
  background: var(--border-color);
  transform: scaleX(0);
  transform-origin: center;
  width: 100%;
  height: var(--border-height);
  left: 0;
  bottom: 0;
  border-radius: 1px;
  transition: transform var(--timing) ease;
}
/* Hover on Input */
.searchBox .form:focus-within {
  border-radius: var(--after-border-radius);
}

.searchBox input:focus {
  outline: none;
}
/* here is code of animated border */
.searchBox .form:focus-within:before {
  transform: scale(1);
}
/* styling of close button */
/* == you can click the close button to remove text == */
.searchBox .reset {
  border: none;
  background: none;
  opacity: 0;
  visibility: hidden;
}
/* close button shown when typing */
.searchBox input:not(:placeholder-shown) ~ .reset {
  opacity: 1;
  visibility: visible;
}
/* sizing svg icons */
.searchBox .form svg {
  width: 17px;
  margin-top: 3px;
}
.fa-sort-up {
  color: rgb(148, 232, 148); /* Apply your desired color */
}

.fa-sort-down {
  color: rgb(148, 232, 148); /* Apply your desired color */
}

.testTable {
  height: 500px;
}

.testTable table {
  width: 100%;
  table-layout: fixed;

}

.testTable table th {
  top: 0;
  position: sticky;
  background-color: #fff;
}

.button-87 {
  margin: 10px;
  padding: 8px 26px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 15px;
  display: block;
  border: 0px;
  font-weight: 700;
  background-image: linear-gradient(45deg, #116165 0%, #40B4E5  51%, #116165  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-87:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.button-87:active {
  transform: scale(0.95);
}

/* Center the loading spinner in the viewport */
.loadposition {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Fix the loader on the screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Optional background */
  z-index: 9999; /* Ensure it's above other content */
}

/* Styling for the loading animation container */
.newloading {
  --speed-of-animation: 0.9s;
  --gap: 6px;
  --first-color: #4c86f9;
  --second-color: #49a84c;
  --third-color: #f6bb02;
  --fourth-color: #f6bb02;
  --fifth-color: #2196f3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px; /* Width of the animation */
  gap: 6px;
  height: 30px; /* Height of the loader */
}

/* Styling for each individual span element */
.newloading span {
  width: 9px;
  height: 40px;
  background: var(--first-color);
  animation: scale var(--speed-of-animation) ease-in-out infinite;
  border-radius: 4px !important;
}

/* Customizing the colors and animation delay for each span */
.newloading span:nth-child(2) {
  background: var(--second-color);
  animation-delay: -0.8s;
}

.newloading span:nth-child(3) {
  background: var(--third-color);
  animation-delay: -0.7s;
}

.newloading span:nth-child(4) {
  background: var(--fourth-color);
  animation-delay: -0.6s;
}

.newloading span:nth-child(5) {
  background: var(--fifth-color);
  animation-delay: -0.5s;
}

/* Keyframes for the "scale" animation */
@keyframes scale {
  0%, 40%, 100% {
    transform: scaleY(0.05); /* Shrinks the bar */
  }

  20% {
    transform: scaleY(1); /* Expands the bar */
  }
}


 /* loader2 */

.custom-loader-new-position {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-loader-new {
  width: 40px;
  height: 40px;
  display: grid;
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side, #0000 40%, #000 41%);
  background: linear-gradient(0deg, #766DF480 50%, #766DF4FF 0) center/4px 100%,
    linear-gradient(90deg, #766DF440 50%, #766DF4BF 0) center/100% 4px;
  background-repeat: no-repeat;
  animation: s3 1s infinite steps(12);
}

.custom-loader-new::before,
.custom-loader-new::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}

.custom-loader-new::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

@keyframes s3 {
  100% {
    transform: rotate(1turn)
  }
}

.loadingPosition {
  position: absolute;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  color: #32c2bd;
  padding-bottom: 40px !important;
}

.redText {
  color: red !important;
}


.password-input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.full-width-marquee {
  position: absolute !important;
  width: 100%;
  overflow: hidden;
  margin-top: 30px !important;
  background-color: #f52121;
  
}

.full-width-marquee marquee {
  width: 100%;
  white-space: nowrap;
  color: white !important;
  font-size: 20px !important;
}
.newtext{
  font-family: serif;
  font-size: 18px !important;
  color: #000000;
}

.printtablenew1 table {
  font-size: 14px !important;
  border: 1px solid rgb(164, 164, 164) !important;
  color: #000000 !important;
}

.printtablenew1 th,
.printtablenew1 td {
  border: 1px solid rgb(164, 164, 164) !important;
  border-bottom: 1px solid rgb(164, 164, 164) !important;
  padding: 3px;
  text-align: left;
  color: #000000 !important;
}
.printtablenew1 tbody tr:hover {
  background-color: rgba(149, 238, 239, 0.871);
}

.sms {
  width: 100%; /* Table takes up full width */
  border-collapse: collapse; /* Merge borders for a cleaner look */
}

.sms th,
.sms td {
  border: 1px solid rgb(164, 164, 164) !important;
  padding: 8px; /* Increased padding for better readability */
  text-align: left;
}

.sms th {
  background-color: #f2f2f2; /* Light background for header */
}

.sms tbody tr:hover {
  background-color: rgba(149, 238, 239, 0.871);
}

@media (max-width: 768px) {
  .sms th,
  .sms td {
    font-size: 12px; /* Adjust font size for smaller screens */
  }
}
.performanceRed{
  color: #f1420d !important;
}
.performanceGreen{
  color: #1c8b5d !important;
}



.react-datepicker {
  position: absolute !important;
  z-index: 2;
}

.example-custom-input {
  display: block;
  width: 100%;
  height: calc(1em + 1.4rem + 1px);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.9;
  color: #464E5F;
  background-color: #96d6cd5e !important;
  background-clip: padding-box;
  border: 1px solid #E5EAEE;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: justify;
  padding-left: 8px !important;
  
}
.example-custom-input-month {
  display: block;
  width: 100%;
  height: calc(1em + 1.4rem + 1px);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.9;
  color: #464E5F;
  background-color: #96d6cd5e !important;
  background-clip: padding-box;
  border: 1px solid #E5EAEE;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: justify;
  padding: 8px !important;
  
}
.mARBOTTOMREMOVE{
  margin-bottom: 0px !important;
}
.calendardesign{
  float: right !important;
}
.react-datepicker__header{
  background-color: rgb(238, 248, 241) !important;
}

.paddingRemove{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.menudotcolor{
  color: #ff8c08 !important;
}
.asideHeading{
  color: #ff8c08 !important;
  font-size: 25px !important;
  text-decoration: none !important;
}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-heading .menu-bullet.menu-bullet-dot > span, .aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link .menu-bullet.menu-bullet-dot > span {
  background-color: #f59630 !important;
  width: 8px !important;
  height: 8px !important;
}


.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-bullet.menu-bullet-dot > span, .aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-bullet.menu-bullet-dot > span {
  background-color: #5c5e81 !important;
  width: 8px !important;
  height: 8px !important;
}
.dashboardfont{
  font-size: 60px;
  font-weight: 800;
}



.center-row {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  
}

.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: .9s;
  --uib-color: #0b8794;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: '';
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
 
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}


/* Drag & Drop */
/* Container Styling */
.menu-permission-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f0f8ff; /* Light background color */
  min-height: 100vh;
}

/* Sections Styling */
.menu-sections {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
}

@media (min-width: 768px) {
  .menu-permission-container {
    flex-direction: row;
  }

  .menu-sections {
    flex-direction: row;
  }
}

/* Menu List and Drag-and-Drop Section Styling */
.menu-list, .drag-and-drop-section {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff; /* White background for contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

@media (min-width: 768px) {
  .menu-list, .drag-and-drop-section {
    width: 50%;
  }
}

/* Header Styling */
.menu-list h3, .drag-and-drop-section h3 {
  margin-top: 0;
  color: #333; /* Darker color for header text */
  padding-bottom: 3px;
}

/* Draggable Menu Item Styling */
.draggable-menu-item {
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: move;
  background-color: #e7f0ff; /* Light blue background */
  border: 1px solid #bcd5ff; /* Light blue border */
  color: #333;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.draggable-menu-item:hover {
  background-color: #d0e7ff; /* Slightly darker blue on hover */
}

.draggable-menu-item.disabled {
  cursor: not-allowed;
  background-color: #f5f5f5; /* Gray background for disabled items */
  text-decoration: line-through;
}

/* Drop Zone Styling */
.drop-zone {
  border: 2px dashed #28a745; /* Green dashed border */
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9; /* Light gray background */
  color: #333;
  min-height: 100px;
}
.drop-zone-p-section{
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Dropped Item Styling */
.dropped-item {
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  background-color: #d4edda; /* Light green background */
  border: 1px solid #c3e6cb; /* Light green border */
  color: #333;
  transition: background-color 0.3s ease;
}

.dropped-item:hover {
  background-color: #c3e6cb; /* Slightly darker green on hover */
}

/* Search Input Styling */
.search-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.search-input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  background-color: #EEEEEE;
}

.search-icon {
  margin-left: -30px;
  cursor: pointer;
}

.loader-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

/* Drag & Drop */

.pageheadingText{
  font-size: 20px;
  font-weight: 600;
  font-family: 'Raleway';
}
.notshow{
  display: none !important;
}

.stickyTable_SO {
  overflow-x: scroll;
  height: 500px;
}

.stickyTable_SO table {
  width: 100%;
  table-layout: fixed;
  overflow-x: scroll;
}

.stickyTable_SO table th {
  top: 0;
  position: sticky;
  background-color: #1bc5bd;
  z-index: 1;
  color: #ffff;
  padding: 7px;
}
.stickyTable_SO table td {
  border: 1px solid #C0C0C0;
  padding: 4px;
}
.stickyTable_SO tbody tr:hover {
  background-color: rgba(149, 238, 239, 0.871);
}

#kt_header {
  background-color: #198ea1 !important;
}


.calendar-date {
  transition: background-color 0.3s;
}

.calendar-date:hover {
  background-color: #f0f0f0;
}

.calendar-date.today {
  background-color: #007bff;
  color: white;
}

.calendar-date.today:hover {
  background-color: #007bff; 
}


.slick-prev:before, .slick-next:before{
  color: #0b8794;
}


/* division category table */

.table-responsive-category {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.custom-table .division-name {
  background-color: #e2e3e5 ;
}

.custom-table .biscuit-achievement {
  background-color: #f8d7da;
}

.custom-table .cookie-achievement {
  background-color:#d1ecf1 ;
}

.custom-table .cake-achievement {
  background-color: #fff3cd;
}

.custom-table .wafer-achievement {
  background-color:#d4edda;
}

.custom-table .snacks-achievement {
  background-color: rgba(153, 102, 255, 0.2);
}

/* division category table */
.congratulations_names{
  font-size: 30px !important;
  color: #333333;
}
.congratulations{
  font-size: 20px !important;
  color: #9540E4;
  font-weight: 600;
}



.visit-color-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.visit-frequency-legend .col-md-3 {
  margin-bottom: 10px;
}

.visit-frequency-legend span {
  font-size: 12px;
  font-weight: 500;
}


.achievement-chart-card {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.spinner-color {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chart-container {
  position: relative;
}


.achievement-text h3 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
  margin-bottom: 8px;
}

.achievement-text p {
  font-size: 16px;
  color: #777;
}

@media screen and (max-width: 768px) {
  .achievement-chart-card {
    width: 100%;
  }

  .achievement-text h2 {
    font-size: 28px;
  }
}

.achievement-text {
  font-size: 1.2rem;
  color: #333;
}

.achievement-text h2 {
  font-size: 3rem;
  font-weight: bold;
  color: #ffbc00;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.text-truncate-distributor {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;

}


/* Main container for dashboard */
.so-dashboard-container {
  padding: 10px 15px 15px 15px;
  font-family: Arial, sans-serif;
}

.so-dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
}

.so-dashboard-avatar {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #C6E2FF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: 600;
  color: #008080;
  margin-right: 15px;
}

.so-dashboard-greeting {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.so-dashboard-email {
  font-size: 14px;
  color: #666;
}

/* Quick info cards */
.so-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 5px;
}

.so-card-top {
  background: linear-gradient(135deg, #FFA726, #FFCC80);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  color: #fff;
}



.so-card-icon {
  font-size: 24px;
  margin-right: 10px;
}

.so-card-title {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.so-card-value {
  font-size: 28px;
  font-weight: bold;
}
.so-card-value-ims {
  font-size: 28px;
  font-weight: bold;
  color: #ffbc00;
}
.so-card-value-stock {
  font-size: 28px;
  font-weight: bold;
  color: #66CDAA;
}

/* Custom colors */
.so-card-achievement {
  padding: 6px; /* Reduced padding */
}

.so-card-achievement-info {
  padding: 3px 7px; /* Reduced padding in the achievement info section */
}

.so-card-icon {
  margin-bottom: 5px; /* Reduce the margin if needed */
}

.so-dashboard-header {
  padding: 10px; /* Reduce padding in the header section */
}

.so-dashboard-avatar {
  padding: 5px; /* Reduce padding around the avatar */
}

.so-card-achievement-info h2 {
  font-size: 2.4rem;
  font-weight: bold;
  color: #ffbc00;
}

/* Quick Launcher */
.so-quick-launcher {
  position: fixed;         /* Fixes the position relative to the viewport */
  bottom: 0;            /* Adjust distance from the bottom of the screen */
  left: 0;                 /* Sticks to the left side of the screen */
  width: 100%;             /* Makes it span the full width */
  background-color: #fff;  /* White background */
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for separation */
  padding: 10px 15px;      /* Padding for better spacing */
  z-index: 999;            /* Ensure it stays on top of other content */
}

.so-quick-launcher-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.so-quick-launcher-icons {
  display: flex;
  justify-content: space-around;
}

.so-quick-launcher-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #333;
  text-align: center;
}

.so-quick-launcher-icon svg {
  font-size: 32px;
  color: #1e88e5;
  margin-bottom: 5px;
}

/* Custom Modal Styling */
.custom-leads-modal .modal-body {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}


.custom-modal-body-content p {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  margin-bottom: 10px;
}

.custom-modal-body-content .custom-modal-content-title {
  font-size: 18px;
  font-weight: 600;
  color: #007bff;
  margin-bottom: 2px;
}

/* Close Button (cross button) */
.custom-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  font-size: 24px;
  color: #007bff;
  padding: 5px;
}

.custom-close-btn:hover {
  color: #ff4757; /* Red color for hover effect */
  cursor: pointer;
}

@media (max-width: 767px) {
  .hide-column {
    display: none;
  }
}


.so-entry-page table {
  width: 100%;
  font-size: 14px !important;
  border: 1px solid #F5F5F5 !important;

}

.so-entry-page th,
.so-entry-page td {
  border: 1px solid #F5F5F5 !important;
  border-bottom: 1px solid #F5F5F5 !important;
  padding: 3px;
  text-align: left;
}
.so-entry-page th {
  background-color: #E6E6FA !important;
}
.so-entry-page tbody tr:hover {
  background-color: #E6E6FA;
}
.so-entry-page-icon {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #333;
}

.so-entry-page-icon svg {
  font-size: 26px;
  color: #1e88e5;
  margin-bottom: 3px;
}

.cart-icon-container {
  position: relative;
  display: inline-block;
}

.cart-count {
  position: absolute;
  top: -5px;
  right: -4px;
  background-color: #ff5733;
  color: white;
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 10px;
  border: 2px solid #ffff !important;
}

.custom-searchBox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}

.custom-logo-container {
  margin-right: 15px;
}

.custom-logo {
  font-size: 24px; /* Adjust size of the search icon */
  color: #4A90E2; /* You can change the color of the icon */
}

.custom-searchBox-entry {
  display: flex;
  justify-content: center;
}

.custom-form {
  display: flex;
}

.custom-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  width: 250px;
  transition: border 0.3s;
}

.custom-input:focus {
  border-color: #4A90E2; /* Change border color on focus */
}

.custom-input::placeholder {
  color: #888;
}

.custom-input:hover {
  border-color: #333; /* Change border color on hover */
}

.italic-section{
  font-size: 11px !important;
}
.no-data-message {
  text-align: center;
  color: black;
  font-size: 12px;
  padding: 10px;
  background-color: #FF8F8F;
  border-radius: 8px;
  border: 1px solid #ddd;
}
.bottom-text-entry-so{
 font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.welcome-card-title {
  font-size: 13px;
  font-weight: 600;
  color: #333;
}

.form-group {
  margin-bottom: 0px !important
}


.report-table-wrapper {
  overflow-x: auto;
  position: relative;
  max-height: 550px;
  overflow-y: auto;
}

.report-table {
  border-collapse: collapse;
  table-layout: fixed;
}

.report-table-header {
  padding: 3px;
  text-align: center;
  border: 1px solid #ddd;
  background-color: #c0c0c0;
  color: #333;
  font-size: 11px;
  position: sticky;
  top: 0;
 
  background-color: #C0C0C0;
}

.report-table-cell {
  padding: 7px;
  text-align: left;
  border: 1px solid #ddd;
}

.report-even-row {
  background-color: #f9f9f9;
}

.report-odd-row {
  background-color: #fff;
}



@media (max-width: 768px) {
  .report-table-wrapper {
      margin-top: 20px;
      overflow-x: auto;
  }

  .report-table {
      font-size: 12px;
  }

  .report-table-header,
  .report-table-cell {
      padding: 10px;
  }

  .report-table {
      min-width: 600px;
  }
}

@media (min-width: 769px) {
  .report-table {
      font-size: 12px;
  }
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
  color: #888;
}



.pvt-product-table table {
  font-size: 14px !important;
  border: 1px solid rgb(164, 164, 164) !important;
  color: #000000 !important;
}

.pvt-product-table th {
  border: 1px solid rgb(164, 164, 164) !important;
  border-bottom: 1px solid rgb(164, 164, 164) !important;
  padding: 3px;
background-color: #D6D6D6;
  color: #000000 !important;
}

.pvt-product-table td {
  border: 1px solid rgb(164, 164, 164) !important;
  border-bottom: 1px solid rgb(164, 164, 164) !important;
  padding: 3px;
  color: #000000 !important;
}
.pvt-product-table tbody tr:hover {
  background-color: #D2D2D2;
}

.warning-msg-entry-page {
  color: red;
  font-size: 9px;
  font-style: italic;
}
.formula-msg-entry-page {
  color: blue;
  font-size: 9px;
  font-style: italic;
}

.secondary-msg-entry-page {
  color: #008080;
  font-size: 10px;
}

.records-view{
  font-size: 12px !important;
  font-style: italic;
  color: #CCCCCC;
}
.distributor-pending-title{
  font-size: 12px !important;
  font-style: italic;
  color: #CCCCCC;
}

.success-msg-entry-page {
  color: blue;
  font-size: 11px;

}

.banktablehead{
  background-color: #007bff !important;
  font-size: 9px !important;
}

.distributor-month-balance {
  font-size: 10px !important;
  border: 1px solid rgb(164, 164, 164) !important;
  color: #000000 !important;
}

.distributor-month-balance th,
.distributor-month-balance td {
  border: 1px solid rgb(164, 164, 164) !important;
  border-bottom: 1px solid rgb(164, 164, 164) !important;
  padding: 2px;
  text-align: left;
  color: #000000 !important;
}
.distributor-month-balance tbody tr:hover {
  background-color: #CCCCCC;
}


.timer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fccd77;
  border: 2px solid #dcdcdc;
  padding: 10px;
  margin-top: 0px;
}

.timer-text {
  font-size: 2rem;
  font-weight: bold;
  color: #007bff;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timer-segment {
  display: flex;
  justify-content: center; 
  align-items: center;  
  width: 40px;
  height: 40px;
  background-color: #e9ecef;
  margin: 0 5px;
  border-radius: 8px;
  font-size: 1.5rem;
}

.timer-segment span {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.hours {
  background-color: #f5e4e4;
}

.minutes {
  background-color: #f5e4e4;
}

.seconds {
  background-color: #f5e4e4; 
}

.timer-label {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-right: 6px;
}


.approve-yes-btn {
  background-color: green !important;
  color: white !important;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.approve-no-btn {
  background-color: red !important;
  color: white !important;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-alert-title {
  font-size: 19px;
  font-weight: 600;
  color: #333;
}


/* Container for the table */
.custom-table-responsive-category {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 500px;
}

/* Table header styles */
.sticky-header {
  background-color: #FFC3A0 !important;
  color: black;
  text-transform: uppercase;
  position: sticky;
  top: 0;
  padding: 6px !important;
}

/* Table styling */
.table-striped th,
.table-striped td {
  padding: 6px;
  text-align: center;
  font-size: 10px !important;
}

/* Alternate row background */
.table-striped tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.table-striped tbody tr:nth-child(even) {
  background-color: #e9ecef;
}

/* Hover effect: highlight row when mouse is over it */
.table-striped tbody tr:hover {
  background-color: #ffc107;  /* Highlight color on hover */
  color: black;  /* Optional: change text color on hover */
}

/* Text alignment for table cells */
.text-center {
  text-align: center;
}

/* Table stripe effect */
.table-striped td,
.table-striped th {
  vertical-align: middle;
}

/* Full background container */
.fullcardbg {
  background-color: #f8f9fa;
}

/* General styling for responsive table */
.table-striped {
  width: 100%;
  border-collapse: collapse;
}

/* Optional: add border to the table for better visibility */
.table-striped th, .table-striped td {
  border: 1px solid #ddd;
}




/* project management */


.pm-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
}

.pm-header {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 36px;
}

/* Role Selector */
.role-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.role-selector label {
  font-size: 18px;
  margin-right: 10px;
}

.role-selector select {
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Full-Page Section */
.pm-fullpage-section {
  display: block;
  height: calc(100vh - 150px); /* 100% height minus the header and role selector */
  overflow: auto;
}

.pm-fullpage {
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pm-fullpage h2 {
  text-align: center;
  font-size: 32px;
  color: #333;
  margin-bottom: 30px;
}

/* Task Management Styles */
.pm-stages {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Space between columns */
}

.pm-stage {
  flex: 1; /* Each stage takes equal width */
  padding: 15px;
  background-color: #f9fafb;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.pm-stage h3 {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
}

.pm-task {
  background-color: #6cafe2;
  color: white;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
  font-size: 14px;
}

.pm-task:hover {
  background-color: #56b7d1;
}

.pm-task + .pm-task-placeholder {
  visibility: visible;
  height: 12px;
  background-color: transparent;
}

/* Buttons for Admin and Project Manager */
.pm-add-task-button, .pm-delete-task-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
}

.pm-delete-task-button {
  background-color: #f44336;
  margin-left: 10px;
}

.pm-add-task-button:hover {
  background-color: #45a049;
}

.pm-delete-task-button:hover {
  background-color: #e53935;
}



/* project management */