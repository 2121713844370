
@media (min-width: 1571px) and (max-width: 3000px) {

  .achHeight{
    height: 415px;
  }
  .text-secondary-money2{
    font-size: 22px;
    font-weight: 200;
    text-align: center;
    letter-spacing: 0px;
    text-shadow: 0px 0px 0px rgba(0,0,0,0.3), 
      0px -4px 10px rgba(255,255,255,0.3);
    font-family: fantasy;
    color: #7d4b10;
  }
  .money {
 
    box-shadow: 0px 3px 6px #2c28281c;
    background: rgb(255, 255, 255);
    padding: 0px 0px;
    text-align: center;
    height: 345px;
    border-radius: 3px;
   
  }
  .text-secondary-money {
    font-size: 22px;
  }
  .text-4th{
    font-size: 22px;
  }
  .text-1st{
    font-size: 22px;
  }
  .text-2nd{
    font-size: 22px;
  }
  
  .text-3rd{
    font-size: 22px;
  }
  .text-1st2{
    font-size: 22px;
  }
  .text-2nd2{
    font-size: 22px;
  }
  
  .text-3rd2{
    font-size: 22px;
  }
  .wpassed {
    font-size: 22px;
    font-weight: 200;
  }
  .sub_card {
    margin: 0px;
  }
  .money span{
    font-size: 10px;
  }
  .moneyheading{
    font-size: 13px !important;
   }
  
  .progress.vertical {
    position: relative;
    width: 40px;
    height: 290px;
    display: inline-block;
    border: 1px solid black;
    
  }

  .progress2.vertical2 {
    position: relative;
    width: 40px;
    height: 290px;
    display: inline-block;
    border: 1px solid black;
    
  }

  .vertical_bar_bottom2 {
    font-size: 9px;
  
}
  
   
  

}


/* hp laptop */

@media (min-width: 1201px) and (max-width: 1570px) {
  /* .Acheivement{
    height: 180px !important;
  } */
  .sales_card {
    margin-top: 5px;
    box-shadow: 0px 3px 6px #2c28281c;
    background: #fff;
    overflow: hidden;
    color: black;
    padding: 19px;
    overflow: hidden;
    position: relative;
    text-align: center;
  }
  .acheivement_card{
    padding: 0px;
    height: 290px;

  }

  .achHeight{
    height: 289px;
  }

  
  /* progress bar - 1*/

.vertical_bar{
  margin-top: 3px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 3px;
  background: #fff;
  color: black;
  padding: 3px;
  position: relative;
  text-align: center;
}

.progress {
  background-color: #f5f5f5;
  border-radius: 3px;
  box-shadow: none;
}

.progress.vertical {
  position: relative;
  width: 40px;
  height: 240px;
  display: inline-block;
  border: 1px solid black;
  
}

.progress {
  height: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress.vertical > .progress-bar {
  width: 100% !important;
  position: absolute;
  bottom: 0;
}

.progress-bar {
  background-color: #3a2205;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #733f03 !important;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

   /* progress bar - 2*/


.vertical_bar2{
  margin-top: 3px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 3px;
  background: #fff;
  color: black;
  padding: 3px;
  position: relative;
  text-align: center;
}

.progress2 {
  background-color: #f5f5f5;
  border-radius: 3px;
  box-shadow: none;
}

.progress2.vertical2 {
  position: relative;
  width: 40px;
  height: 240px;
  display: inline-block;
  border: 1px solid black;
  
}

.progress2 {
  height: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress2.vertical2 > .progress-bar2 {
  width: 100% !important;
  position: absolute;
  bottom: 0;
}

.progress-bar2 {
  background-color: #0b8794;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.progress-bar2 {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #0b8794;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.vertical_bar_bottom1{
  box-shadow: 0px 3px 6px #2c28281c;
  border: solid rgb(99, 74, 4) 1px;
  /* border-radius: 3px; */
  background: white;
  color: rgb(99, 74, 4);
  padding: 3px;
  position: relative;
  text-align: center;
  font-weight: 600;
  font-size: 6px;
  margin-top: 0 !important;
}
.vertical_bar_bottom2{
  box-shadow: 0px 3px 6px #2c28281c;
  border: solid #0b8794 1px;
  /* border-radius: 3px; */
  background: white;
  color: rgb(99, 74, 4);
  padding: 3px;
  position: relative;
  text-align: center;
  font-weight: 600;
  font-size: 6px;
  margin-top: 0 !important;
}

.money {
 
  box-shadow: 0px 3px 6px #2c28281c;
  background: rgb(255, 255, 255);
  padding: 0px 0px;
  text-align: center;
  height: 290px;
  border-radius: 3px;

 
}
.text-secondary-money3 {
  font-size: 18px;
  font-weight: 200;
  text-align: center;
  letter-spacing: 0px;
  /* text-shadow: 0px 0px 0px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(255,255,255,0.3); */
  font-family: fantasy;

}
.vertical_bar_bottom_color_1 {
  color: #ffff;
  background-color: #7d4b10;
}

.vertical_bar_bottom_color_2 {
  color: #ffff;
  background-color: #0b8794;
}
.text-secondary-money {
  font-size: 20px;
}
.text-4th{
  font-size: 18px;
}
.text-1st{
  font-size: 26px;
}
.text-2nd{
  font-size: 18px;
}

.text-3rd{
  font-size: 22px;
}
.text-1st2{
  font-size: 22px;
}
.text-2nd2{
  font-size: 22px;
}

.text-3rd2{
  font-size: 22px;
}
.wpassed {
  font-size: 22px;
  font-weight: 200;
}
.sub_card {
  margin: 0px;
}
.money span{
  font-size: 10px;
}
.moneyheading{
  font-size: 13px !important;
 }

 /* barchart */
 .cuswidth{
  width: 570px !important;
 }
 .cuswidth1{
  width: 1200px !important;
 }
 .dailysalesmargin{
  padding-left: 45px;
}
.sub_top_card_dashboardChart {
  top: 145px;
  left: 270px;
  font-size: 10px;
}
.sub2_top_card_dashboardChart {
  top: 130px;
  left: 270px;
  font-size: 10px;
}
.cuswidth3{
  width: 256px;
  text-align: center !important;
  justify-content: center !important;
  margin: auto;
}
.moneyheading{
  font-size: 16px !important;
}
.text-secondary-money2{
  font-size: 20px;
}

}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) and (max-width: 1199.98px) {
  .widget__right h4 {
    
    font-size: 17px;
}
.voyage-activity-vessel {
  margin-top: 0px!important;
}
a.pl-3.pr-3.text-bold.btn.btn-primary.text-white.btn-sm {
  margin-top: 5px;
}
.acvhei{
  height: 180px !important;
}
.cuswidth{
  width: 560px !important;
 }
.col-lg-2.primary {
  padding: 6px !important;
}
}

@media (min-width: 991px) and (max-width: 1200px) {
  .progress.vertical {
    height: 170px;
    width: 40px;
  }
  .vertical_bar_bottom1{
    font-size: 6px;
  }
  .progress2.vertical2 {
    height: 170px;
    width: 40px;
  }
  .vertical_bar_bottom2 {
    font-size: 6px;
  }
  .money {
    padding-top: 2px;
    height: 239px;
  }
  .moneyheading1{
    font-size: 12px !important;
  }
  .moneyheading2{
    font-size: 12px !important;
  }
  .sub_card {
    border-bottom: 0px !important;
    margin: 0px !important;
  }
  .text-secondary-money2{
    font-size: 20px;
  }
  .text-secondary-money{
    font-size: 20px;
  }
  .text-4th{
    font-size: 20px;
  }
  .text-2nd{
    font-size: 20px;
  }
  .wpassed{
    font-size: 20px;
  }
  .text-3rd{
    font-size: 20px;
  }

}

/* All Mobile Devices */

@media (min-width: 320px) and (max-width: 991.98px) {
  .flex-row-fluid.d-flex.flex-column.position-relative.loginPage {
    max-width: 100% !important;
  }
  .login-aside.d-flex.flex-row-auto.loginBg {
    max-width: 100%;
  }
   /* barchart */
 .cuswidth{
  width: 960px !important;
 }
 .col-lg-2.primary {
  padding: 6px;
}

}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) and (max-width: 991.98px) {
  .login.login-1 .login-aside {
    height: 100vh;
  }
  .dashboard__pb {
    padding-bottom: 0px;
  }
  .chart__box {
    height: 410px;
  }
  .dough__main {
    padding-top: 0px;
  }
  .doug__left {
    padding-top: 0px;
  }

  .chart__box {
    height: 407px !important;
  }
  .login-signin {
    margin-top: -78px;
  }
  .cerificateCategory {
    width: 84%;
  }
  .dashboard-cardsection {
    margin-bottom: -11px !important;
  }
  .topbar {
    background-color: #3699ff;
    z-index: 95 !important;
  }
  .header-fixed.subheader-fixed .subheader {
    z-index: 93 !important;
  }
  .dashboard-cardsection {
    padding-top: 45px !important;
  }
  .purchase-table{
    margin-top: 10px;
  }
  .dashboard-cardsection {
    padding-top: 3px !important;
}
.header-fixed.subheader-fixed .header {
  background: transparent;
   
 }
 /* .header.header-fixed {
  height: 16px !important;
 } */
 .MuiTab-root {
  min-width: 108px !important;
  
}
label.form-control.criteriaOptionsInput {
  padding-bottom: 42px;
  
}
.stickeyCard.card {
  margin-left: -40px;
}
.css-yk16xz-control {
  width: 100%!important;
}
a.pl-3.pr-3.text-bold.btn.btn-primary.text-white.btn-sm {
  margin-top: 5px;
}
.user-list-addbutton{
  margin-top: 5px;
  margin-left: 10px;
}
 /* barchart */
 .cuswidth{
  width: 660px !important;
 }
 .col-lg-2.primary {
  padding: 6px;
}
.header.header-fixed{
  height: 0px !important;
}


}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 426px) and (max-width: 767.98px) {
  .header.header-fixed{
    height: 0px !important;
  }
  .cerificateCategory {
    width: 84%;
  }
  .dashboard-cardsection {
    margin-bottom: -11px !important;
  }
  .topbar {
    background-color: #3699ff;
    z-index: 95 !important;
  }
  .header-fixed.subheader-fixed .subheader {
    z-index: 93 !important;
  }
  .dashboard-cardsection {
    padding-top: 3px !important;
  }
  .paid__widget {
    margin-bottom: 0px;
  }
  .container.dashboard__pb {
    margin-top: 23px;
}
.purchase-table{
  margin-top: 10px;
}
label.voyagelist-type.form-label {
  margin-left: 10px;
}
label.mt-2.voyagelist-formlabel.form-label {
  margin-left: 10px;
}
button.MuiButtonBase-root.MuiTab-root {
  width: 73px!important;
  min-width: 2px!important;
 
  font-size: 9px!important;
  text-transform: capitalize!important;
  padding: 6px 8px!important;
}
.header-fixed.subheader-fixed .header {
  background: transparent;
   
 }
 /* .header.header-fixed {
  height: 16px !important;
 } */
 label.form-control.criteriaOptionsInput {
  padding-bottom: 78px;
  
}
.form-check.radio__button.Promotion-radio-button {
  margin-left: 15px;
}
.form-check.radio__button.Promotion-radio-button {
  margin-left: 15px;
}
.Promotion-radio-no-button {
  margin-left: 15px;
}
.laytimecCommenced {
  margin-left: 24px;
}
.laytimecCompleted{
  margin-left: 24px!important;
}
.float-left.Rate-commision.one {
  width: 16%;
}
.float-right.Rate-commision.one {
 
  display: none;
}
.float-left.Rate-commision {
  width: 32%;
  
}
.float-right.Rate-commision {
 
  display: none;
}
.form-group label {
  font-size: 10px !important;
}
.float-left.Rate-commision.two {
  width: 20%;
}
.float-right.Rate-commision.two {
  
  display: none;
}
i.fas.fa-trash-alt.editIcon.item-list-icon {
  margin-left: 0px!important;
}
label.voyage-vessel.form-label {
  margin-left: 12px;
}
.user-list-addbutton{
  margin-top: 5px;
  margin-left: 12px;
}
.user-list-dropdown{
  margin-top: 5px;
  }
  .callITsupport{
    font-size: 20px;
  }
   /* barchart */
 .cuswidth{
  width: 520px !important;
 }
 .BottomNavigationBar{
  display: none !important;
}
.smallscreendisplaynone{
  display: none !important;
}
 
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 425px) {
  .dashboard__pb {
    padding: 10px;
  }
  .dough__main,
  .doug__left {
    padding-top: 0px !important;
  }
  .chart__box {
    height: 506px !important;
  }
  .loginBg {
    display: none !important;
  }
  .login-signin {
    margin-top: -150px !important;
  }
  .btn.btn-icon.btn-clean.btn-dropdown.btn-lg.mr-1 {
    margin-right: 8px !important;
  }
  h1.tableheading {
    margin-left: 13px;
  }
  i.fas.fa-calendar-alt {
    top: 29px;
    left: 245px;
    color: #b5b5c3 !important;
  }
  .tbl-survey {
    width: 100% !important;
  }
  .voyageTable i.fas.fa-trash-alt.editIcon.ml-4 {
    margin-left: 0px !important;
  }
  .voyageTable td,
  th,
  ._1hzhf {
    font-size: 10px !important;
  }
  button.btn.approve {
    font-size: 9px;
  }
  i.editIcon {
    font-size: 10px;
    padding: 6px;
    margin-right: 4px;
  }
  .tbl-standard thead tr th {
    font-size: 9px !important;
  }
  .form-group label.voyagelist-formlabel {
    font-size: 10px !important;
    margin-left: 15px;
  }
  ._3uApM {
    margin-top: -8px !important;
    margin-left: 19px;
  }
  .form-group label {
    font-size: 11px !important;
  }
  label.form-label.mt-2.formFont {
    margin-top: 0px !important;
  }
  ._2Jtxm._35Ago ._2kL0S {
    margin-left: 0px;
  }
  ._2Jtxm {
    margin-left: 0px;
  }
  ._1Lo2h {
    width: 97%;
  }
  ._hsN1w {
    margin-right: 16px;
  }
  h3 {
    font-size: 14px !important;
  }
  .header-fixed.subheader-fixed.subheader-enabled .wrapper {
    padding-top: 6px !important;
  }
  table.table.table.item-add-table th,
  tr,
  td {
    font-size: 10px !important;
  }
  .item-list-icon.ml-4 {
    margin-left: 0px !important;
  }
  .completion-date {
    margin-top: 14px;
  }
  i.fas.fa-calendar-alt {
    left: 145px !important;
    top: 22px !important;
  }
  .booking-entry-input.form-group {
    margin-left: -13px;
    width: 105% !important;
  }
  button.booking-listadd-btn {
    font-size: 9px;
  }
  i.item-list-icon {
    font-size: 8px !important;
    margin-left: 0px !important;
  }
  .row.custom-modal {
    width: 329px;
  }
  .aside {
    width: 184px;
  }

  h5.text-muted.font-weight-bold.my-2.mr-5.ml-5 {
    font-size: 13px !important;
    width: 55% !important;
  }
  .dashboard-cardsection {
    margin-bottom: -11px !important;
  }
  .topbar {
    background-color: #3699ff;
    z-index: 95 !important;
  }
  .header-fixed.subheader-fixed .subheader {
    z-index: 93 !important;
  }
  .dashboard-cardsection {
    padding-top: 45px !important;
  }
  .purchase-table {
    margin-top: 10px;
  }
  .linechart {
    margin-top: -18px;
  }
  .float-left.booking-text {
    width: 23%;
  }

  .booking-text.float-right {
    width: 74%;
  }
  .float-left.booking-text.one {
    width: 25%;
  }

  .booking-text.float-right.one {
    width: 70%;
  }
  .float-left.booking-text.two {
    width: 23%;
  }

  .booking-text.float-right.two {
    width: 67%;
  }
  .cerificateCategory {
    width: 87%;
  }
  .cerificateCategory {
    width: 87%;
  }
  .offcanvas.offcanvas-right {
    right: -395px;
    left: auto;
    display: none;
  }
  .certificate-icon {
    margin-left: 0px !important;
  }
  .stickeyCard.card {
    margin-left: -40px;
  }
  input.color-picker {
    height: 18px;
    width: 18px;
  }
  .due-days h6 {
    top: 3px;
    left: 22px;
    font-size: 8px !important;
  }
  .certificate-filter {
    margin-left: 16px;
  }
  .certificate-card.card-body {
    padding: 10px !important;
  }
  .headerText {
    padding-top: 0px !important;
  }
  .voyagelist-type.form-label {
    margin-left: 14px;
  }
  .form-label {
    margin-bottom: 2px !important;
  }
  .card {
    margin-top: 7px;
  }
  .voyage-vessel {
    margin-top: 3px !important;
  }
  .laytimecCommenced {
    margin-left: 24px !important;
  }
  i.fas.fa-calendar-alt {
    display: none;
  }

  .commence-date {
    margin-top: 15px;
  }
  .float-left.Rate-commision {
    width: 48%;
    margin-left: 11px;
  }
  .float-right.Rate-commision {
    width: 48%;
    margin-top: -6px;
  }
  .float-left.Rate-commision.one {
    width: 26%;
  }
  .float-right.Rate-commision.one {
    width: 70%;
  }
  .float-left.Rate-commision.two {
    width: 30%;
  }
  .float-right.Rate-commision.two {
    width: 65%;
  }
  .export {
    margin-left: 15px;
  }
  .voyage-vessel {
    margin-left: 14px !important;
    margin-top: -11px !important;
  }
  .custom-border {
    margin-left: 0px !important;
  }
  .userlist-select {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .additional__comments label.form-check-label {
    font-size: 12px;
  }
  .radio-button {
    margin-left: 15px;
  }
  .radio-button {
    margin-left: 29px;
  }
  .col-form-label {
      padding-top: 0px!important;
      padding-bottom: 0px!important;
}
.employee-cr-report-date{
    margin-top: 0px!important;
    margin-bottom: 0px!important;
}
.employee-cr-report-input{
    margin-top: -19px;
}

button.MuiButtonBase-root.MuiTab-root {
  width: 56px!important;
  min-width: 2px!important;
 
  font-size: 9px!important;
  text-transform: capitalize!important;
  padding: 6px 3px!important;
}
.Promotion-radio-button{
  margin-left: 13px;
}
.user-list-addbutton{
  margin-top: 5px;
  margin-left: 11px;
}
.user-list-dropdown{
margin-top: 5px;
}
a.ml-2.user-list-icon {
  margin-left: 0px!important;
}
.udevelopmentText{
  font-size: 15px !important;
  font-weight: 800;
}
.callITsupport{
  font-size: 18px;
  padding-top: 0px;
}
 /* barchart */
 .cuswidth{
  width: 320px;
 }
.titleposition{
  justify-content: center;
  align-items: center;
}
.totalwidth{
  width: 80px !important;
}
.BottomNavigationBar{
  display: none !important;
}
.smallscreendisplaynone{
  display: none !important;
}
.paid__widget {
  margin-bottom: 0px;
}

}

@media (max-width: 426px) {
  .loginBg {
    height: 200px;
  }
  .dashboard__pb {
    padding: 10px;
  }
  .dough__main,
  .doug__left {
    padding-top: 0px !important;
  }
  .chart__box {
    height: 506px !important;
  }
  .empty-space {
    width: 48% !important;
  }
  .udevelopmentText{
    font-size: 14px !important;
    font-weight: 800;
  }
  .callITsupport{
    font-size: 15px;
    padding-top: 10px;
  }
  .Acheivement{
    height: 150px !important;
  }
  .acheivement_chart_card{
    padding: 0px;
  }
   /* barchart */
 .cuswidth{
  width: 320px;
 }

.stickyTablecus table{
  width: 100%;
  table-layout: auto !important; 
}
.titleposition{
  justify-content: center;
  align-items: center;
}
.totalwidth{
  width: 80px !important;
}
.BottomNavigationBar{
  display: none !important;
}
.smallscreendisplaynone{
  display: none !important;
}
.paid__widget {
  margin-bottom: 0px;
}
 
}

@media (max-width: 375px) {
  i.fas.fa-calendar-alt {
    top: 26px;
    left: 203px;
    color: #b5b5c3 !important;
  }
  a.pl-3.pr-3.text-bold.btn.btn-primary.text-white.btn-sm {
    margin-top: 6px;
}
.udevelopmentText{
  font-size: 12px !important;
  font-weight: 800;
}
.Acheivement{
  height: 150px !important;
}
.achi{
  height: 100px !important;
  align-items: center;
}
.achv{
  width: 350px !important;
}
.acheivement_card{
  padding: 0px;
  height: 280px;
}
.acheivement_chart_card{
  padding: 0px;
}
 /* barchart */
 .cuswidth{
  width: 320px!important;
 }
 .dailysalesmargin{
  padding-left: none;
}

.stickyTablecus table{
  width: 100%;
  table-layout: auto !important; 
}
.titleposition{
  justify-content: center;
  align-items: center;
}
.progress.vertical {
  height: 140px;
  width: 40px;
}
.progress2.vertical2{
  height: 140px;
  width: 40px;
}
.moneyheading1{
  font-size: 16px !important;
}
.moneyheading2{
  font-size: 16px !important;
}
.money {
  padding-top: 10px;
  margin-top: 3px;
}
.text-4th{
  font-size: 22px;
}
.text-2nd{
  font-size: 22px;
}

.text-3rd{
  font-size: 22px;
}
.text-1st2{
  font-size: 22px;
}
.text-2nd2{
  font-size: 22px;
}

.text-3rd2{
  font-size: 22px;
}
.wpassed {
  font-size: 22px;
  font-weight: 200;
}
.text-secondary-money{
  font-size: 22px;
}
.text-secondary-money2{
  font-size: 22px;
}
.money {
  height: 350px;
}
.sub_card {
  border-bottom: 1px solid #f7f7f7;
  margin: 3px;
}
.vertical_bar{
box-shadow: 0px 0px 0px #2c28281c;
}
.col-lg-2.primary{
  padding: 0px !important;
}
.marginremove{
  margin-top: 0px !important;
}
.card{
  margin-top: 0px !important;
}
.cuswidth3{
  width:250px !important;
}
.totalwidth{
  width: 80px !important;
}
.BottomNavigationBar{
  display: none !important;
}
.smallscreendisplaynone{
  display: none !important;
}
.paid__widget {
  margin-bottom: 0px;
}


}
