/*********************************** Sidebar **************************/
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
#kt_header_mobile a img {
  width: 60px !important;
  margin-left: 5px;
  margin-top: -7px;
}

.brand-toggle svg {
  color: #fff !important;
}

.brand-dark .header-mobile {
  background-color: #1e1e2d;
}

.brand-dark .brand {
  background-color: #1e1e2d;
}

.aside-menu {
  background-color: #1e1e2d;
}

.aside {
  background-color: #1e1e2d;
}

.menu-icon svg {
  width: 17px !important;
}

.aside .aside-menu .menu-nav {
  margin: 0;
  list-style: none;
  padding: 0px 0;
  width: 270px;
}

.menu-item .menu-link {
  background: #1e1e2d !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: #1d3d74;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link {
  background-color: #1d3d74;
}

.aside-menu .menu-nav > .menu-section .menu-text {
  color: #4a4b68;
}
.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  color: #b5b5c3;
}

.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  font-weight: 400;
  font-size: 0.9rem;
  text-transform: none;
  text-transform: initial;
}

.aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon {
  flex: 0 0 25px;
  font-size: 1.3rem;
}

.aside-menu .menu-nav > .menu-item > .menu-link {
  padding: 5px 25px;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link {
  cursor: pointer;
  min-height: 25px;
}

.aside-menu .menu-nav > .menu-item > .menu-heading,
.aside-menu .menu-nav > .menu-item > .menu-link {
  cursor: pointer;
  min-height: 35px;
}
.asideHeading {
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.07em;
  text-decoration: none !important;
}

.serchproduct {
  width: 100%;
  background: #f7f7f7;
}

.serchproduct p {
  display: block;
  padding: 10px;
  border: 1px
  solid #f5f5f5;
}

.serchproduct p:hover {
  background: #ccc;
  cursor: pointer;
}

/*********************************** Sidebar **************************/

.invoice {
  background: #fff;
  padding: 20px
}

.invoice-company {
  font-size: 20px;
  font-weight: 600;
  margin-top: -17px;
}

.invoice-header {
  margin: 0 -20px;
  background: #f0f3f4;
  padding: 20px
}

.invoice-date,
.invoice-from,
.invoice-to {
  display: table-cell;
  width: 1%
}

.invoice-from,
.invoice-to {
  padding-right: 20px
}

.invoice-date .date,
.invoice-from strong,
.invoice-to strong {
  font-size: 16px;
  font-weight: 600
}

.invoice-date {
  text-align: right;
  padding-left: 20px
}

.invoice-price {
  background: #f0f3f4;
  display: table;
  width: 100%
}

.invoice-price .invoice-price-left,
.invoice-price .invoice-price-right {
  display: table-cell;
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
  width: 75%;
  position: relative;
  vertical-align: middle
}

.invoice-price .invoice-price-left .sub-price {
  display: table-cell;
  vertical-align: middle;
  padding: 0 20px
}

.invoice-price small {
  font-size: 12px;
  font-weight: 400;
  display: block
}

.invoice-price .invoice-price-row {
  display: table;
  float: left
}

.invoice-price .invoice-price-right {
  width: 25%;
  background: #f0f3f4;
  color: #100909;
  font-size: 28px;
  text-align: right;
  vertical-align: bottom;
  font-weight: 900;
}

.invoice-price .invoice-price-right small {
  display: block;
  opacity: .6;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px
}

.invoice-footer {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  font-size: 10px
}

.invoice-note {
  color: #999;
  margin-top: 80px;
  font-size: 85%
}


.invoice>div:not(.invoice-footer) {
  margin-bottom: 20px
}

.btn.btn-white, .btn.btn-white.disabled, .btn.btn-white.disabled:focus, .btn.btn-white.disabled:hover, .btn.btn-white[disabled], .btn.btn-white[disabled]:focus, .btn.btn-white[disabled]:hover {
  color: #2d353c;
  background: #fff;
  border-color: #d9dfe3;
}
